import logo from '../assets/logo.png';
import settingsIcon from '../assets/settings-icon.svg';
import { Link } from 'react-router-dom';

import ProfileMenu from './ProfileMenu';
import NotificationDropdown from './NotificationDropdown';

function TopBar({ notificationDropdownToggle, notificationUpdatedFlag = false }) {
    return (
        <div className="top-bar border-b pb-2 flex justify-between">
            <div className="logo-container">
                <Link to="/">
                    <img src={logo} alt="logo" />
                </Link>
            </div>
            <div className="notification-container">
                <div className="flex justify-center">
                    <div className="w-12 mt-2">
                        {/* <img className="ml-auto" src={bellIcon} alt="Notifications" /> */}
                        <NotificationDropdown notificationUpdatedFlag={notificationUpdatedFlag} toggleNotificationDropdown={(data) => notificationDropdownToggle(data)} />
                    </div>
                    <div className="w-12 mt-2">
                        <Link to="/admin/settings">
                            <img className="flex items-center p-2 text-gray-600 hover:text-gray-800 focus:outline-none" src={settingsIcon} alt="Settings" />
                        </Link>
                    </div>
                    <div className="w-12 relative">
                        <ProfileMenu />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TopBar;