import { Link, useLocation } from 'react-router-dom'
import Li from "./Li";

function TopNavigation() {
    const location = useLocation();
    const links = [
        { label: 'Dashboard', path: '/admin/dashboard' },
        { label: 'Users', path: '/admin/users' },
        { label: 'Bols', path: '/admin/bols' },
        { label: 'Pickup Requests', path: '/admin/pickup-requests' },
        { label: 'Drivers', path: '/admin/drivers' },
        { label: 'Trucks', path: '/admin/trucks' },
        { label: 'Truck Features', path: '/admin/truck-features' },
        // { label: 'Grab Jobs', path: '/admin/grab-jobs' },
        // { label: 'Reports', path: '/admin/reports' },
        { label: 'Customers', path: '/admin/customers' },
        // { label: 'Shipments', path: '/admin/shipments' },
        // { label: 'Proforma Invoice', path: '/admin/proforma-invoice' },
        // { label: 'Manage Users', path: '/admin/manage-users' },
        // { label: 'Check-In Freight', path: '' },
        { label: 'Zones', path: '/admin/zones' },
        { label: 'Fleet Control', path: '/admin/fleet-control' },
        { label: 'Locations', path: '/admin/locations' },
        { label: 'Invoice', path: '/admin/invoices' },
        { label: 'Charges', path: '/admin/charges' },
        { label: 'Price Tiers', path: '/admin/price-tiers' },
    ];

    const renderedLinks = links.map((link, index) => {
        return (
            <Li
                key={link.label}
                className={index === 0 ? 'nav-link-1 px-0 mr-[32px]' : 'nav-link xl:px-5 md:px-1 text-nowrap mr-[32px]'}
            >
                <Link
                    key={link.label}
                    to={link.path}
                    // className="text-sm leading-5 font-medium text-gray-500 text-nowrap"
                    className={`text-[16px] font-inter py-4 leading-5 font-medium text-gray-500 text-nowrap ${location.pathname === link.path ? 'md:border-b-4 md:border-[#1E40AF] text-[#1E40AF]' : ''}`}
                >
                    {link.label}
                </Link>
            </Li>
        );
    });

    return (
        <div className="top-navigation">
            <ul className="block md:flex flex-wrap justify-start m-4 pb-4 px-0">
                {renderedLinks}
            </ul>
        </div>
    );
}

export default TopNavigation;