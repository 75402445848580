import PageHeading from "../components/PageHeading";

function UserPage() {
    return (
        <div className="content px-3 lg:px-8">
            <div className="content-bar flex justify-between mt-6">
                <PageHeading>Users</PageHeading>
            </div>

            {/* <div className="mt-8 w-3/4">
                <div className='flex items-center justify-end mb-3'>
                    <Link
                        to={"/admin/drivers/create"}
                        className=''
                    >
                        <button type="submit" className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 flex">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            <span className="ml-2">New User</span>
                        </button>
                    </Link>
                </div>
                {loading ?
                    <Loader />
                    :
                    <div className="not-prose relative bg-gray-50 rounded-xl overflow-hidden">
                        <div className="absolute inset-0"></div>
                        <div className="relative rounded-xl overflow-auto">
                            <div className="shadow-sm overflow-hidden mt-7">
                                <SortableTable tableLayout="row" data={data} config={config} keyFun={keyFun} />
                            </div>
                        </div>
                        <div className="flex justify-between items-center self-stretch py-6">
                            {count > 0 ? <div>Showing {offset + 1} to {paginationLastLimitHandler()} of {count} results</div> : null}
                            {count > recordsPerPage ?
                                <div className="flex">
                                    <Pagination
                                        to="/drivers"
                                        currentPage={page}
                                        totalPages={Math.ceil(count / recordsPerPage)}
                                        onPageChange={handlePageChange}
                                    />
                                </div>
                                : null}
                        </div>
                    </div>
                }
            </div> */}
        </div>
    );
}

export default UserPage;