import React from "react";
import PickupInvoicePage from "../../invoices/PickupInvoicePage";

const CustomerInvoices = ({ customerId }) => {
    return (
        <div>
            {/* <h4 className="text-xl font-medium capitalize px-8">Customer Invoices(ID: {customerId})</h4> */}
            <div className="mt-3">
                <PickupInvoicePage id={customerId} />
            </div>
        </div>
    )
}

export default CustomerInvoices;