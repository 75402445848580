import React, { useState } from "react";
import { DateRangePicker } from "react-date-range";
import { enUS } from 'date-fns/locale';

const DatePickerComponent = ({ getUpdatedDateRange }) => {
    const [date, setDate] = useState({
        startDate: new Date(),
        endDate: null,
        key: 'selection',
    });

    const [showDateFilter, setShowDateFilter] = useState(false);

    const handleChange = (ranges) => {
        const { startDate, endDate } = ranges.selection;
        setDate({ ...date, startDate, endDate });

    };

    const dateFilterToggle = () => {
        setShowDateFilter(!showDateFilter);
    };

    const applyDateFilterHandle = () => {
        getUpdatedDateRange({ startDate: date.startDate, endDate: date.endDate });
        setShowDateFilter(!showDateFilter);
    }

    const cancelDateFilterHandle = () => {
        setShowDateFilter(!showDateFilter);
    }

    return (
        <div className="relative">
            <button onClick={() => dateFilterToggle()} type="button" className="rounded-md border bg-white px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-black-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <span className="ml-2">Date Filter</span>
            </button>
            {showDateFilter && <div className="absolute top-[45px] z-10 rounded-md border overflow-hidden">
                <DateRangePicker
                    locale={enUS}
                    ranges={[date]}
                    onChange={handleChange}
                />
                <div className="flex gap-3 px-4 mb-4 bg-white">
                    <button onClick={() => applyDateFilterHandle()} className="mt-3 rounded-md border bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-black-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 flex items-center">Apply</button>
                    <button onClick={() => cancelDateFilterHandle()} className="mt-3 rounded-md border bg-slate-300 px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-black-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 flex items-center">Cancel</button>
                </div>
            </div>}
        </div>
    )
}

export default DatePickerComponent;