import React, { useState, useEffect } from 'react';

import PageHeading from "../../components/PageHeading";

import { useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader';
import GraphMutation from '../../components/graphql/mutations/Mutation';

function ZoneCreatePage() {
    const loading = useState(false);
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        title: '',
        zipCodes: [
            { title: '' }
        ],
    });

    const { executeMutation: createZone, responseData: zoneData, errors: zoneError } = GraphMutation(
        { name: 'Zone', expectedResponseField: 'zone', method: 'create' },
        { name: 'zone', type: 'CreateZoneInput' },
        `
            id,
            title,
            zoneZipCodes {
                id,
                zipCode,
            }
        `
    );


    useEffect(() => {
        if (zoneData && zoneData.zoneZipCodes) {
            window.location.href = '/admin/zones';
        }
    }, [zoneData])

    const handleSubmit = async (e) => {
        e.preventDefault();
        let input = {
            title: formData.title,
            zipCodes: formData.zipCodes,
        }
        await createZone(input);
    }

    const handleCancel = () => {
        navigate("/admin/zones");
    }

    const handleChange = (property, e) => {
        setFormData({
            ...formData,
            [property]: e.target.value
        })
    }

    const ZipCodesChangeHandle = (property, e, index) => {
        const updatedZipCodes = [...formData.zipCodes];
        updatedZipCodes[index][property] = e.target.value;

        setFormData({
            ...formData,
            zipCodes: updatedZipCodes
        })
    }


    const renderZipCodes = () => {
        return formData.zipCodes.map((zipCode, index) => {
            return (
                <div key={index} className="md:w-2/4 px-3 mb-3 items-center">
                    <div className="md:w-full">
                        <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="zipcode-title">
                            Zip Code {index + 1}
                        </label>
                        <div className="relative">
                            <input value={zipCode.title} onChange={(value) => ZipCodesChangeHandle('title', value, index)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4" id="zipcode-title" type="text" autoComplete="on" placeholder="Zip Code" required />

                            {index > 0 && <div className="absolute z-20 top-4 right-2 flex items-center justify-center">
                                <svg onClick={(e) => removeZipCodeHandle(e, index)} className="fill-slate-500 hover:fill-red-500" width={15} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                    <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                                </svg>
                            </div>}
                        </div>
                    </div>
                    {zoneError && zoneError[`zipCode.${index}.title`] && <p className="text-red-500 text-xs italic">{zoneError[`zipCode.${index}.title`]}</p>}
                </div>
            )
        })
    }

    const addZipCodeHandle = () => {
        let updatedZipCodes = [];
        updatedZipCodes = formData.zipCodes;
        updatedZipCodes.push({ title: '' })
        setFormData({
            ...formData,
            zipCodes: updatedZipCodes,
        })
    }

    const removeZipCodeHandle = (e, idx) => {
        e.preventDefault();
        let filteredZipCodes = formData.zipCodes.filter((zipCode, index) => index !== idx);
        return setFormData({
            ...formData,
            zipCodes: filteredZipCodes,
        })
    }

    return (
        <div className="content px-3 lg:px-8">
            <div className="content-bar flex justify-between mt-6">
                <PageHeading>Add New Zone</PageHeading>
            </div>
            <div className="mt-8">
                <div className="not-prose relative bg-gray-50 rounded-xl overflow-hidden">
                    <div className="absolute inset-0"></div>
                    {loading === true ?
                        <Loader />
                        : <div className="relative rounded-xl overflow-auto">
                            <div className="shadow-sm overflow-hidden mt-7 px-4">
                                {
                                    zoneData && zoneData.zipCodes && <div className="text-green-500">Zone added successfully.</div>
                                }

                                {/* Create Driver Form  */}
                                <div className="row">
                                    <div className="md:w-2/4">
                                        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col my-2">

                                            <div className="-mx-3 md:flex mb-6">
                                                <div className="md:w-2/4 px-3 flex items-center">
                                                    <div className='md:w-full'>
                                                        <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="zone-title">
                                                            Title
                                                        </label>
                                                        <input value={formData.title} onChange={(value) => handleChange('title', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4" id="zone-title" type="text" autoComplete="on" placeholder="Zone Title" required />
                                                        {zoneError && zoneError.title && <p className="text-red-500 text-xs italic">{zoneError.title}</p>}
                                                    </div>
                                                </div>
                                                <div className="md:w-1/4 px-3 flex items-end justify-end mb-4">
                                                    <button onClick={() => addZipCodeHandle()} type="submit" className="rounded-md bg-indigo-600 py-3 px-4 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 flex">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                        </svg>
                                                        <span className="ml-2">Add New ZipCode</span>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="-mx-3 flex flex-wrap mb-6">
                                                {renderZipCodes()}
                                            </div>

                                            <div className="flex justify-end mt-6">
                                                <button onClick={handleCancel} className="px-6 py-2 mr-3 leading-5 text-black transition-colors duration-200 transform bg-gray-300 rounded-md hover:bg-gray-400 focus:outline-none focus:bg-gray-500">Cancel</button>
                                                <button onClick={handleSubmit} className="px-6 py-2 leading-5 text-white transition-colors duration-200 transform bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600">Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    <div className="absolute inset-0 pointer-events-none border border-black/1 rounded-xl"></div>
                </div>
            </div>
        </div >
    );
}

export default ZoneCreatePage;