import React, { useState, useContext, useEffect } from 'react';
import PageHeading from "../../components/PageHeading";
import { gql, useMutation, useQuery, useLazyQuery } from '@apollo/client';

import { AuthContext } from '../../context/authContext';
import { useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2'
import Select from '../../components/Select';
import CustomSelect from '../../components/CustomSelect';
import CustomerNotes from './CustomerNotes';
import GraphQuery from '../../components/graphql/queries/Query';
import SortableTable from '../../components/SortableTable';

function CustomerCreatePage() {
    const navigate = useNavigate();
    const { token } = useContext(AuthContext);
    const [stateOptions, setStateOptions] = useState([]);
    const [priceTierOptions, setPriceTierOptions] = useState([]);
    const [cityOptions, setCityOptions] = useState([]);
    const [billingCityOptions, setBillingCityOptions] = useState([]);

    const [showPriceTierTable, setShowPriceTierTable] = useState(false);
    const [selectedPriceTier, setSelectedPriceTier] = useState(null);

    const [customerLabelSearchValue, setCustomerLabelSearchValue] = useState('');
    const [customerTagSearchValue, setCustomerTagSearchValue] = useState('');
    const [customerLabelOptions, setCustomerLabelOptions] = useState([
        { id: 1, title: 'cash only customer', slug: 'cash-only-customer' },
        { id: 2, title: 'legacy customer', slug: 'legacy-customer' },
        { id: 3, title: 'new customer', slug: 'new-customer' },
        { id: 4, title: 'online customer', slug: 'online-customer' },
    ]);
    const [customerPaymentOptions, setCustomerPaymentOptions] = useState([
        { id: 1, title: 'Cash On Delivery', slug: 'cash-on-delivery' },
        { id: 2, title: 'Cash In Advance', slug: 'cash-in-advance' },
        { id: 3, title: 'NET15', slug: 'net15' },
        { id: 4, title: 'NET30', slug: 'net30' },
    ]);
    const [customerTagOptions, setCustomerTagOptions] = useState([
    ]);
    const [formData, setFormData] = useState({
        businessName: '',
        creditLimit: 0,
        priceTier: '',
        paymentMethod: '',
        customerLabels: [],
        customerTags: [],
        customerNote: '',
        firstName: '',
        lastName: '',
        username: '',
        contactPersonEmail: '',
        password: '',
        contactPersonPhoneNumber: '',
        contactPersonMobileNumber: '',
        websiteUrl: '',
        businessAddress1: '',
        businessAddress2: '',
        businessAddress3: '',
        state: '',
        city: '',
        zipCode: '',
        billingAddress1: '',
        billingAddress2: '',
        billingAddress3: '',
        billingState: '',
        billingCity: '',
        billingZipCode: '',
        sameBillingAddress: false,
    });
    // const data = useState([]);
    const [errors, setErrors] = useState([]);
    const [disableCities, setDisableCities] = useState(true);

    const priceTierRulesConfig = [
        {
            label: 'Minimum Amount',
            render: (priceTierRule) => priceTierRule.amountMinimumValue,
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4',
        },
        {
            label: 'Maximum Amount',
            render: (priceTierRule) => priceTierRule.amountMaximumValue,
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4',
        },
        {
            label: 'Discount Type',
            render: (priceTierRule) => priceTierRule.ruleType,
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4',
        },
        {
            label: 'Discount Value',
            render: (priceTierRule) => {
                if (priceTierRule.ruleType === 'percentage') {
                    return priceTierRule.ruleTypeValue + '%';
                }
                if (priceTierRule.ruleType === 'fixed') {
                    return priceTierRule.ruleTypeValue + '$';
                }
            },
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4',
        }
    ];

    const keyFun = (priceTierRule) => {
        return priceTierRule.id
    }

    const { responseData: priceTierData, dataCount: priceTierCount, loading: priceTierLoading } = GraphQuery({ name: 'PriceTiers', single: 'PriceTier' }, {
        first: 10,
        skip: 0,
        sortBy: 'title',
        order: 'asc',
    }, `
        id,
        title,
        priceTierRules {
            id,
            amountMinimumValue,
            amountMaximumValue,
            ruleType,
            ruleTypeValue,
        }`);

    // const [accountOptions, setAccountOptions] = useState([]);
    // const [userOptions, setUserOptions] = useState([]);

    // const { data: graphData } = useQuery(GRAPHQL_QUERY, {});

    const CREATE_CUSTOMER = gql`
        mutation($customerNew: CreateCustomerInput!) {
            createCustomer(customerData: $customerNew) {
                customer {
                    id,
                    businessName,
                    customerLabels,
                    businessAddress1,
                    contactPersonFullName,
                    contactPersonEmail,
                    websiteUrl,
                    createdBy,
                }
            }
        }
    `;

    const GET_US_STATES = gql`
        query GetUSStates($first: Int, $skip: Int, $search: String, $sortBy: USStateSortInput) {
            usStates(first: $first, skip: $skip, search: $search, sortBy: $sortBy) {
                id
                name,
                code,
            },
        }
    `;

    const GET_CITIES = gql`
        query GetCities($stateId: ID,$first: Int, $skip: Int, $search: String, $sortBy: CitySortInput) {
            cities(stateId: $stateId, first: $first, skip: $skip, search: $search, sortBy: $sortBy) {
                id
                name,
                state {
                    name
                }
            },
        }
    `;

    const { data: usStateData, refetch } = useQuery(GET_US_STATES, {
        context: {
            headers: {
                Authorization: `JWT ${token}`,
            },
        },
        variables: {
            search: '',
            sortBy: {
                field: 'name',
                direction: 'asc',
            },
        },
    });

    const [fetchCities, { data: cityData, error }] = useLazyQuery(GET_CITIES, {
        context: {
            headers: {
                Authorization: `JWT ${token}`,
            },
        },
    });

    const [fetchBillingCities, { data: billingCityData }] = useLazyQuery(GET_CITIES, {
        context: {
            headers: {
                Authorization: `JWT ${token}`,
            },
        },
    });

    const [createCustomer, { loading, data }] = useMutation(CREATE_CUSTOMER, {
        onError: (error) => {
            try {
                const parsedError = JSON.parse(error.message);
                setErrors(parsedError);
            } catch (parseError) {
                setErrors('An error occurred while processing your request.');
            }

        },
        onCompleted: () => {
            window.location.href = '/admin/customers';
        }
    })


    const handleChange = (property, e) => {
        setFormData({
            ...formData,
            [property]: e.target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        // return console.log(formData, 'formData');
        createCustomer({
            context: {
                headers: {
                    Authorization: `JWT ${token}`,
                },
            },
            variables: {
                customerNew: {
                    businessName: formData.businessName,
                    creditLimit: parseFloat(formData.creditLimit),
                    priceTier: formData.priceTier,
                    paymentMethod: formData.paymentMethod,
                    customerLabels: formData.customerLabels,
                    customerTags: formData.customerTags,
                    customerNote: formData.customerNote,
                    firstName: formData.firstName,
                    lastName: formData.lastName,
                    username: formData.username,
                    contactPersonEmail: formData.contactPersonEmail,
                    password: formData.password,
                    contactPersonPhoneNumber: formData.contactPersonPhoneNumber,
                    contactPersonMobileNumber: formData.contactPersonMobileNumber,
                    websiteUrl: formData.websiteUrl,
                    businessAddress1: formData.businessAddress1,
                    businessAddress2: formData.businessAddress2,
                    businessAddress3: formData.businessAddress3,
                    state: formData.state,
                    city: formData.city,
                    zipCode: formData.zipCode ? formData.zipCode : '',
                    billingAddress1: formData.billingAddress1,
                    billingAddress2: formData.billingAddress2,
                    billingAddress3: formData.billingAddress3,
                    billingState: formData.billingState,
                    billingCity: formData.billingCity,
                    billingZipCode: formData.billingZipCode ? formData.billingZipCode : '',
                }
            }
        });
    }

    useEffect(() => {
        if (priceTierData && priceTierData.length > 0) {
            const priceTierOptions = priceTierData;
            setPriceTierOptions(priceTierOptions);
        }
    }, [priceTierData, setPriceTierOptions]);

    useEffect(() => {
        if (usStateData && usStateData.usStates) {
            const states = usStateData.usStates;
            setStateOptions(states);
        }
    }, [usStateData, setStateOptions]);

    useEffect(() => {
        if (cityData && cityData.cities) {
            const cities = cityData.cities;
            setCityOptions(cities);
        }
    }, [cityData, setCityOptions]);

    useEffect(() => {
        if (billingCityData && billingCityData.cities) {
            setBillingCityOptions(billingCityData.cities);
        }
    }, [billingCityData]);

    useEffect(() => {
        fetchCities({
            variables: {
                stateId: formData.state,
                search: '',
                sortBy: {
                    field: 'name',
                    direction: 'asc',
                },
            },
        });
        if (formData.sameBillingAddress == true) {
            setFormData({
                ...formData,
                billingState: formData.state,
                billingCity: formData?.city,
            });
        }
    }, [formData?.state, formData?.city]);

    useEffect(() => {
        fetchBillingCities({
            variables: {
                stateId: formData.billingState,
                search: '',
                sortBy: {
                    field: 'name',
                    direction: 'asc',
                },
            },
        });
    }, [formData?.billingState]);

    const handleCancel = () => {
        navigate("/admin/customers");
    }

    if (loading) return <p>Loading...</p>;

    const stateHandle = (e, property) => {
        setFormData({
            ...formData,
            [property]: e.target.value,
        });
        setDisableCities(false);

    };

    const priceTierSelectHandle = (e, property) => {
        let priceTier = priceTierOptions?.filter(option => option.id == e?.target?.value)[0];
        console.log(priceTier, 'price tier', e.target.value);
        if (priceTier) {
            setSelectedPriceTier(priceTier)
        }
        setFormData({
            ...formData,
            [property]: e.target.value,
        });
    };

    const cityHandle = (e) => {
        setFormData({
            ...formData,
            city: e.target.value,
        });

    };

    const handleCheckboxChange = () => {
        let isChecked = formData.sameBillingAddress;
        if (isChecked == false) {
            setFormData({
                ...formData,
                sameBillingAddress: !isChecked,
                billingAddress1: formData.businessAddress1,
                billingAddress2: formData.businessAddress2,
                billingAddress3: formData.businessAddress3,
                billingState: formData.state,
                billingCity: formData.city,
                billingZipCode: formData.zipCode,

            })

        }
        else {
            setFormData({
                ...formData,
                sameBillingAddress: !isChecked,
                billingAddress1: '',
                billingAddress2: '',
                billingAddress3: '',
                billingState: '',
                billingCity: '',
                billingZipCode: '',

            })
        }
    };

    const mobileNumberHandle = (mobileNumber) => {
        setFormData({
            ...formData,
            contactPersonMobileNumber: mobileNumber,
        })
    }

    const customerLabelSearchHandle = (data) => {
        setCustomerLabelSearchValue(data);
    }

    const customerTagSearchHandle = (data) => {
        setCustomerTagSearchValue(data);
    }

    const suggestionHandler = (data) => {
        console.log(data, 'sugg handler');
    }

    const removeSelectedCustomerLabel = (option) => {
        // let prevData = selectedCusotmerLabels;
        // let updatedData = prevData.filter(label => label != option);
        // setSelectedCustomerLabels(updatedData);
        // // let prevData = setSelectedCustomerLabels((...prevOption) => prevOptions != option)
        // return console.log('prevData', prevData, 'selectedCustomerLabels', selectedCusotmerLabels, 'option', option)
    }

    const selectCustomerLabelsHandle = (data) => {
        // setSelectedCustomerLabels(data)
        setFormData({
            ...formData,
            customerLabels: data,
        });
    }

    const selectCustomerTagsHandle = (data) => {
        // setSelectedCustomerLabels(data)
        setFormData({
            ...formData,
            customerTags: data,
        });
    }

    const updateOptionsHandle = (option) => {
        let prevData = customerLabelOptions;
        setCustomerLabelOptions(() => {
            if (prevData?.some(item => item.slug == option.slug)) {
                return [...customerLabelOptions];
            }
            return [...customerLabelOptions, option]
        })
        console.log('updated customer options', customerLabelOptions)
    }

    const updateTagOptionsHandle = (option) => {
        let prevData = customerTagOptions;
        setCustomerTagOptions(() => {
            if (prevData?.some(item => item.slug == option.slug)) {
                return [...customerTagOptions];
            }
            return [...customerTagOptions, option]
        })
        console.log('updated customer options', customerTagOptions)
    }

    const showPriceTierTableHandle = () => {
        setShowPriceTierTable(!showPriceTierTable);
    }


    return (
        <div className="px-3 content lg:px-8">
            <div className="flex justify-between mt-6 content-bar">
                <PageHeading>Add New Cutomer</PageHeading>
            </div>
            <div className="mt-8">
                <div className="relative overflow-hidden not-prose bg-gray-50 rounded-xl">
                    <div className="absolute inset-0"></div>
                    <div className="relative overflow-auto rounded-xl">
                        <div className="px-4 overflow-hidden shadow-sm mt-7">
                            {
                                data && data.createCustomer?.customer && < div className='text-green-500'>Customer added successfully.</div>
                            }


                            {/* Create Driver Form  */}
                            <div className='flex row'>
                                <div className='w-3/6 md:w-3/6'>
                                    <div className="flex flex-col px-8 pt-6 pb-8 my-2 mb-4 bg-white rounded shadow-md">

                                        <div className="mb-6 -mx-3 md:flex">
                                            <div className="px-3 md:w-1/2">
                                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="contact_person_first_name">
                                                    Contact Person First Name
                                                </label>
                                                <input value={formData.firstName} onChange={(value) => handleChange('firstName', value)} className="block w-full px-4 py-3 border rounded appearance-none bg-grey-lighter text-grey-darker" id="contact_person_first_name" type="text" autoComplete="on" placeholder="First Name" required />
                                                {errors && errors.firstName && <p className="text-xs italic text-red-500">{errors.firstName}</p>}
                                            </div>
                                            <div className="px-3 md:w-1/2">
                                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="contact_person_last_name">
                                                    Contact Person Last Name
                                                </label>
                                                <input value={formData.lastName} onChange={(value) => handleChange('lastName', value)} className="block w-full px-4 py-3 border rounded appearance-none bg-grey-lighter text-grey-darker border-grey-lighter" id="contact_person_last_name" type="text" autoComplete="on" placeholder="Last Name" required />
                                                {errors && errors.firstName && <p className="text-xs italic text-red-500">{errors.firstName}</p>}
                                            </div>
                                        </div>
                                        <div className="mb-6 -mx-3 md:flex">
                                            <div className="px-3 md:w-full">
                                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="business_name">
                                                    Business Name
                                                </label>
                                                <input value={formData.businessName} onChange={(value) => handleChange('businessName', value)} className="block w-full px-4 py-3 border rounded appearance-none bg-grey-lighter text-grey-darker" id="business_name" type="text" autoComplete="on" placeholder="Business Name" required />
                                                {errors && errors.businessName && <p className="text-xs italic text-red-500">{errors.businessName}</p>}
                                            </div>
                                        </div>
                                        <div className="mb-6 -mx-3 md:flex">
                                            <div className="px-3 md:w-1/2">
                                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="customer_username">
                                                    Username
                                                </label>
                                                <input value={formData.username} onChange={(value) => handleChange('username', value)} className="block w-full px-4 py-3 border rounded appearance-none bg-grey-lighter text-grey-darker" id="customer_username" type="text" autoComplete="on" placeholder="Username" required />
                                                {errors && errors.username && <p className="text-xs italic text-red-500">{errors.username}</p>}
                                            </div>
                                            <div className="px-3 md:w-1/2">
                                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="customer_email">
                                                    Email
                                                </label>
                                                <input value={formData.contactPersonEmail} onChange={(value) => handleChange('contactPersonEmail', value)} className="block w-full px-4 py-3 border rounded appearance-none bg-grey-lighter text-grey-darker border-grey-lighter" id="customer_email" type="email" autoComplete="on" placeholder="Email" required />
                                                {errors && errors.contactPersonEmail && <p className="text-xs italic text-red-500">{errors.contactPersonEmail}</p>}
                                            </div>
                                        </div>
                                        <div className="mb-6 -mx-3 md:flex">
                                            <div className="px-3 md:w-1/2">
                                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="customer_password">
                                                    Password
                                                </label>
                                                <input value={formData.password} onChange={(value) => handleChange('password', value)} className="block w-full px-4 py-3 border rounded appearance-none bg-grey-lighter text-grey-darker" id="customer_password" type="password" autoComplete="on" placeholder="Password" required />
                                                {errors && errors.password && <p className="text-xs italic text-red-500">{errors.password}</p>}
                                            </div>
                                            <div className="px-3 md:w-1/2">
                                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="customer_phone_number">
                                                    Phone Number
                                                </label>
                                                <input value={formData.contactPersonPhoneNumber} onChange={(value) => handleChange('contactPersonPhoneNumber', value)} className="block w-full px-4 py-3 border rounded appearance-none bg-grey-lighter text-grey-darker border-grey-lighter" id="customer_phone_number" type="text" autoComplete="on" placeholder="Phone Number" required />
                                            </div>
                                        </div>
                                        <div className="mb-6 -mx-3 md:flex">
                                            <div className="px-3 md:w-1/2">
                                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="mobile_number">
                                                    Mobile Number
                                                </label>
                                                <PhoneInput
                                                    className="border-0"
                                                    country={'us'}
                                                    value={formData.contactPersonMobileNumber}
                                                    onChange={mobileNumberHandle}
                                                />
                                                {errors && errors.contactPersonMobileNumber && <p className="text-xs italic text-red-500">{errors.contactPersonMobileNumber}</p>}
                                                {/* <p className="text-xs italic text-red-500">Please fill out this field.</p> */}
                                            </div>
                                            <div className="px-3 md:w-1/2">
                                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="customer_webiste_url">
                                                    Website URL
                                                </label>
                                                <input value={formData.websiteUrl} onChange={(value) => handleChange('websiteUrl', value)} className="block w-full px-4 py-3 border rounded appearance-none bg-grey-lighter text-grey-darker border-grey-lighter" id="customer_webiste_url" type="text" autoComplete="on" placeholder="https://www.example.com" required />
                                            </div>
                                        </div>
                                        <div className="mb-6 -mx-3 md:flex">
                                            <div className="px-3 md:w-1/2">
                                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="business_name">
                                                    Select Customer tags
                                                </label>
                                                <CustomSelect labelColor='#9694FF' labelTextColor='#FFFFFF' multiple={true} selectedOptions={formData.customerTags} searchValue={customerTagSearchValue} setSearchValue={customerTagSearchHandle} options={customerTagOptions} placeholderValue="Select Customer Tags" getSuggestions={(value) => suggestionHandler(value)} setSelectedItems={selectCustomerTagsHandle} updateOptions={updateTagOptionsHandle} />
                                            </div>
                                            <div className="px-3 md:w-1/2">
                                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="business_name">
                                                    Select Customer Labels
                                                </label>
                                                <CustomSelect labelColor="#FF9C73" labelTextColor='#000000' multiple={true} selectedOptions={formData.customerLabels} searchValue={customerLabelSearchValue} setSearchValue={customerLabelSearchHandle} options={customerLabelOptions} placeholderValue="Select Customer Label" getSuggestions={(value) => suggestionHandler(value)} setSelectedItems={selectCustomerLabelsHandle} updateOptions={updateOptionsHandle} />
                                            </div>
                                        </div>
                                        <div className="mb-6 -mx-3 md:flex">
                                            <div className="px-3 md:w-1/2">
                                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="customer_payment_method">
                                                    Payment Method
                                                </label>
                                                <div className="relative mb-3 sm:mb-0">
                                                    <select value={formData.paymentMethod} onChange={(e) => handleChange('paymentMethod', e)} className="block w-full px-4 py-3 pr-8 border rounded appearance-none bg-grey-lighter border-grey-lighter text-grey-darker" id="customer_payment_method">
                                                        <option value="null">Select Payment Method</option>
                                                        {
                                                            customerPaymentOptions.map((term, index) => {
                                                                return (
                                                                    <option key={index} value={term.slug}>{term.title}</option>
                                                                );
                                                            })
                                                        }
                                                    </select>
                                                    <div className="absolute right-0 flex items-center px-2 pointer-events-none pin-y pin-r text-grey-darker top-4">
                                                        <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                    </div>
                                                    {errors && errors.paymentMethod && <p className="text-xs italic text-red-500">{errors.paymentMethod}</p>}
                                                </div>
                                                {/* <input id="customer_payment_method" value={formData.paymentMethod} onChange={(value) => handleChange('payment', value)} className="block w-full px-4 py-3 border rounded appearance-none bg-grey-lighter text-grey-darker" type="number" autoComplete="on" placeholder="Credit Limit" required /> */}
                                                {errors && errors.creditLimit && <p className="text-xs italic text-red-500">{errors.creditLimit}</p>}
                                            </div>
                                            <div className="px-3 md:w-1/2">
                                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="customer_credit_limit">
                                                    Credit Limit
                                                </label>
                                                <input id="customer_credit_limit" value={formData.creditLimit} onChange={(value) => handleChange('creditLimit', value)} className="block w-full px-4 py-3 border rounded appearance-none bg-grey-lighter text-grey-darker" type="number" autoComplete="on" placeholder="Credit Limit" required />
                                                {errors && errors.creditLimit && <p className="text-xs italic text-red-500">{errors.creditLimit}</p>}
                                            </div>
                                        </div>
                                        <div className="mb-6 -mx-3 md:flex">
                                            <div className="px-3 md:w-1/3">
                                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="customer_price_tier">
                                                    Select Price Tier <span onClick={() => showPriceTierTableHandle()} className='text-blue-900 capitalize text-xs hover:underline cursor-pointer'>{showPriceTierTable === true ? 'Hide' : "Show"} Table</span>
                                                </label>
                                                <div className="relative">
                                                    <select value={formData.priceTier} onChange={(e) => priceTierSelectHandle(e, 'priceTier')} className="block w-full px-4 py-3 pr-8 border rounded appearance-none bg-grey-lighter border-grey-lighter text-grey-darker" id="customer_price_tier">
                                                        <option value="null">Select Price Tier</option>
                                                        {
                                                            priceTierOptions.map((priceTier, index) => {
                                                                return (
                                                                    <option key={priceTier.id} value={priceTier.id}>{priceTier.title}</option>
                                                                );
                                                            })
                                                        }
                                                    </select>
                                                    <div className="absolute right-0 flex items-center px-2 pointer-events-none pin-y pin-r text-grey-darker top-4">
                                                        <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                    </div>

                                                </div>
                                                {errors && errors.priceTier && <p className="text-xs italic text-red-500">{errors.priceTier}</p>}
                                            </div>
                                        </div>
                                        {showPriceTierTable && selectedPriceTier && <div className="mb-6 -mx-3 px-3">
                                            <div className='border rounded-md'>
                                                <h3 className='text-lg font-semibold mb-4 p-3'>Table View ({selectedPriceTier?.title})</h3>
                                                <div>
                                                    <div className="shadow-sm overflow-hidden mt-7">
                                                        <SortableTable tableHeaderTextColor="text-black" tableLayout="row" data={selectedPriceTier?.priceTierRules} config={priceTierRulesConfig} keyFun={keyFun} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}
                                        <div className="-mx-3 md:flex">

                                            <div className='w-full px-3'>
                                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="customer_notes">
                                                    Customer Note (Optional)
                                                </label>
                                                <textarea id='customer_notes' placeholder='' className="w-full px-2 border rounded" onChange={(value) => handleChange('customerNote', value)} value={formData.customerNote}></textarea>
                                            </div>
                                        </div>
                                        <h4 className='my-3 text-xl font-bold'>Consignee Address</h4>
                                        <div className="mb-6 -mx-3 md:flex">
                                            <div className="px-3 md:w-full">
                                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="customer_address_1">
                                                    Address Line 1
                                                </label>
                                                <input value={formData.businessAddress1} onChange={(value) => handleChange('businessAddress1', value)} className="block w-full px-4 py-3 mb-3 border rounded appearance-none bg-grey-lighter text-grey-darker" id="customer_address_1" type="text" autoComplete="on" placeholder="Address Line 1" required />
                                                {/* <p className="text-xs italic text-red-500">Please fill out this field.</p> */}
                                            </div>
                                        </div>
                                        <div className="mb-6 -mx-3 md:flex">
                                            <div className="px-3 md:w-full">
                                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="customer_address_2">
                                                    Address Line 2
                                                </label>
                                                <input value={formData.businessAddress2} onChange={(value) => handleChange('businessAddress2', value)} className="block w-full px-4 py-3 mb-3 border rounded appearance-none bg-grey-lighter text-grey-darker" id="customer_address_2" type="text" autoComplete="on" placeholder="Address Line 2" required />
                                                {/* <p className="text-xs italic text-red-500">Please fill out this field.</p> */}
                                            </div>
                                        </div>
                                        <div className="mb-6 -mx-3 md:flex">
                                            <div className="px-3 md:w-full">
                                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="customer_address_3">
                                                    Address Line 3
                                                </label>
                                                <input value={formData.businessAddress3} onChange={(value) => handleChange('businessAddress3', value)} className="block w-full px-4 py-3 mb-3 border rounded appearance-none bg-grey-lighter text-grey-darker" id="customer_address_3" type="text" autoComplete="on" placeholder="Address Line 3" required />
                                                {/* <p className="text-xs italic text-red-500">Please fill out this field.</p> */}
                                            </div>
                                        </div>
                                        <div className="-mx-3 md:flex">
                                            <div className="px-3 md:w-1/3">
                                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="customer_state">
                                                    State
                                                </label>
                                                <div className="relative">
                                                    <select onChange={(e) => stateHandle(e, 'state')} className="block w-full px-4 py-3 pr-8 border rounded appearance-none bg-grey-lighter border-grey-lighter text-grey-darker" id="customer_state">
                                                        <option value="null">Select State</option>
                                                        {
                                                            stateOptions.map((state, index) => {
                                                                return (
                                                                    <option key={state.code} value={state.code}>{state.name}</option>
                                                                );
                                                            })
                                                        }
                                                    </select>
                                                    <div className="absolute right-0 flex items-center px-2 pointer-events-none pin-y pin-r text-grey-darker top-4">
                                                        <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                    </div>

                                                </div>
                                                {errors && errors.state && <p className="text-xs italic text-red-500">{errors.state}</p>}
                                            </div>
                                            <div className="px-3 md:w-1/3">
                                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="customer_city">
                                                    City
                                                </label>
                                                <div className="relative">
                                                    <select disabled={disableCities} onChange={(e) => cityHandle(e, 'city')} className="block w-full px-4 py-3 pr-8 border rounded appearance-none bg-grey-lighter border-grey-lighter text-grey-darker" id="customer_city">
                                                        <option value="null">Select City</option>
                                                        {
                                                            cityOptions.map((city) => {
                                                                return (
                                                                    <option value={city.name} key={city.id}>{city.name}</option>
                                                                );
                                                            })
                                                        }
                                                    </select>
                                                    <div className="absolute right-0 flex items-center px-2 pointer-events-none pin-y pin-r text-grey-darker top-4">
                                                        <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                    </div>

                                                </div>
                                                {errors && errors.city && <p className="text-xs italic text-red-500">{errors.city}</p>}
                                            </div>
                                            <div className="px-3 md:w-1/3">
                                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="customer_zipCode">
                                                    Zip Code
                                                </label>
                                                <input value={formData.zipCode} onChange={(value) => handleChange('zipCode', value)} className="block w-full px-4 py-3 border rounded appearance-none bg-grey-lighter text-grey-darker" id="customer_zipCode" type="text" autoComplete="on" placeholder="Zip Code" required />
                                                {errors && errors.zipCode && <p className="text-xs italic text-red-500">{errors.zipCode}</p>}
                                            </div>
                                        </div>
                                        <div className="">
                                            <div className='inline-flex items-center'>
                                                <label className="relative flex items-center py-3 rounded-full cursor-pointer" htmlFor="check">
                                                    <input checked={formData?.sameBillingAddress} onChange={handleCheckboxChange} type="checkbox"
                                                        className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-gray-900 checked:before:bg-gray-900 hover:before:opacity-10"
                                                        id="check" />
                                                    <span
                                                        className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor"
                                                            stroke="currentColor" strokeWidth="1">
                                                            <path fillRule="evenodd"
                                                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                                clipRule="evenodd"></path>
                                                        </svg>
                                                    </span>
                                                </label>
                                                <label className="mt-px ml-3 font-light text-gray-700 cursor-pointer select-none" htmlFor="check">
                                                    Billing Address same as Consignee Address
                                                </label>
                                            </div>
                                        </div>
                                        <h4 className='my-3 text-xl font-bold'>Billing Address</h4>
                                        <div className="mb-6 -mx-3 md:flex">
                                            <div className="px-3 md:w-full">
                                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="customer_billing_address_1">
                                                    Address Line 1
                                                </label>
                                                <input value={formData.billingAddress1} onChange={(value) => handleChange('billingAddress1', value)} className="block w-full px-4 py-3 mb-3 border rounded appearance-none bg-grey-lighter text-grey-darker" id="customer_billing_address_1" type="text" autoComplete="on" placeholder="Billing Address Line 1" required />
                                                {/* <p className="text-xs italic text-red-500">Please fill out this field.</p> */}
                                            </div>
                                        </div>
                                        <div className="mb-6 -mx-3 md:flex">
                                            <div className="px-3 md:w-full">
                                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="customer_billing_address_2">
                                                    Address Line 2
                                                </label>
                                                <input value={formData.billingAddress2} onChange={(value) => handleChange('billingAddress2', value)} className="block w-full px-4 py-3 mb-3 border rounded appearance-none bg-grey-lighter text-grey-darker" id="customer_billing_address_2" type="text" autoComplete="on" placeholder="Billing Address Line 2" required />
                                                {/* <p className="text-xs italic text-red-500">Please fill out this field.</p> */}
                                            </div>
                                        </div>
                                        <div className="mb-6 -mx-3 md:flex">
                                            <div className="px-3 md:w-full">
                                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="customer_billing_address_3">
                                                    Address Line 3
                                                </label>
                                                <input value={formData.billingAddress3} onChange={(value) => handleChange('billingAddress3', value)} className="block w-full px-4 py-3 mb-3 border rounded appearance-none bg-grey-lighter text-grey-darker" id="customer_billing_address_3" type="text" autoComplete="on" placeholder="Billing Address Line 3" required />
                                                {/* <p className="text-xs italic text-red-500">Please fill out this field.</p> */}
                                            </div>
                                        </div>
                                        <div className="-mx-3 md:flex">
                                            <div className="px-3 md:w-1/3">
                                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="customer_billing_state">
                                                    State
                                                </label>
                                                <div className="relative">
                                                    <select value={formData.billingState ? formData.billingState : ''} onChange={(e) => stateHandle(e, 'billingState')} className="block w-full px-4 py-3 pr-8 border rounded appearance-none bg-grey-lighter border-grey-lighter text-grey-darker" id="customer_billing_state">
                                                        <option value="null">Select State</option>
                                                        {
                                                            stateOptions.map((state, index) => {
                                                                return (
                                                                    <option key={state.code} value={state.code}>{state.name}</option>
                                                                );
                                                            })
                                                        }
                                                    </select>
                                                    <div className="absolute right-0 flex items-center px-2 pointer-events-none pin-y pin-r text-grey-darker top-4">
                                                        <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                    </div>

                                                </div>
                                                {errors && errors.billingState && <p className="text-xs italic text-red-500">{errors.billingState}</p>}
                                            </div>
                                            <div className="px-3 md:w-1/3">
                                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="customer_billing_city">
                                                    City
                                                </label>
                                                <div className="relative">
                                                    <select value={formData.billingCity ? formData.billingCity : ''} disabled={disableCities} onChange={(e) => stateHandle(e, 'billingCity')} className="block w-full px-4 py-3 pr-8 border rounded appearance-none bg-grey-lighter border-grey-lighter text-grey-darker" id="customer_billing_city">
                                                        <option value="null">Select City</option>
                                                        {
                                                            billingCityOptions.map((city) => {
                                                                return (
                                                                    <option value={city.name} key={city.id}>{city.name}</option>
                                                                );
                                                            })
                                                        }
                                                    </select>
                                                    <div className="absolute right-0 flex items-center px-2 pointer-events-none pin-y pin-r text-grey-darker top-4">
                                                        <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                    </div>

                                                </div>
                                                {errors && errors.billingCity && <p className="text-xs italic text-red-500">{errors.billingCity}</p>}
                                            </div>
                                            <div className="px-3 md:w-1/3">
                                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="customer_billing_zipCode">
                                                    Zip Code
                                                </label>
                                                <input value={formData.billingZipCode} onChange={(value) => handleChange('billingZipCode', value)} className="block w-full px-4 py-3 border rounded appearance-none bg-grey-lighter text-grey-darker" id="customer_billing_zipCode" type="text" autoComplete="on" placeholder="Zip Code" required />
                                                {errors && errors.billingZipCode && <p className="text-xs italic text-red-500">{errors.billingZipCode}</p>}
                                            </div>
                                        </div>
                                        <div className="flex justify-end mt-6">
                                            <button onClick={handleCancel} className="px-6 py-2 mr-3 leading-5 text-black transition-colors duration-200 transform bg-gray-300 rounded-md hover:bg-gray-400 focus:outline-none focus:bg-gray-500">Cancel</button>
                                            <button type='submit' onClick={handleSubmit} className="px-6 py-2 leading-5 text-white transition-colors duration-200 transform bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600">Save</button>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className='w-3/6 ml-4 md:w-2/6'>
                                    <div className="flex flex-col px-8 pt-6 pb-8 my-2 mb-4 bg-white rounded shadow-md">
                                        <CustomerNotes/>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="absolute inset-0 border pointer-events-none border-black/1 rounded-xl"></div>
                </div>
            </div>
        </div >
    );
}

export default CustomerCreatePage;