import './assets/css/App.css';

import { BrowserRouter, Routes, Route } from 'react-router-dom'
import BolsPage from './pages/BolsPage';
import BolDetailPage from './pages/BolDetailPage';
import DashboardPage from './pages/DashboardPage';
import UserPage from './pages/UserPage';
import HomePage from './pages/HomePage';
import RegisterPage from './pages/users/RegisterPage';
import RegisterPageStep2 from './pages/users/RegisterPageStep2';
import WelcomePage from './pages/users/WelcomePage';
import LoginPage from './pages/users/LoginPage';
import AboutPage from './pages/AboutPage';
import ProfilePage from './pages/ProfilePage';
import PickupRequestPage from './pages/PickupRequestPage';
import PickupRequestDetailPage from './pages/PickupRequestDetailPage';
import TrackingPublicPage from './pages/users/TrackingPublicPage';
import DriversPage from './pages/drivers/DriversPage';
import DriverCreatePage from './pages/drivers/DriverCreatePage';
import TrucksPage from './pages/trucks/TrucksPage';
import TruckCreatePage from './pages/trucks/TruckCreatePage';
import TruckEditPage from './pages/trucks/TruckEditPage';
import TruckFeaturesPage from './pages/truck_features/TruckFeaturesPage';
import TruckFeatureCreatePage from './pages/truck_features/TruckFeatureCreatePage';
import TruckFeatureEditPage from './pages/truck_features/TruckFeatureEditPage';
import ZonesPage from './pages/zones/ZonesPage';

import ComingSoonPage from './pages/ComingSoonPage';
import CommonLayout from './pages/layouts/CommonLayout';
import AdminLayout from './pages/layouts/AdminLayout';

import Protected from './components/Protected';
import VerifyLogin from './components/VerifyLogin';
import { AuthProvider } from './context/authContext';


import DriverEditPage from './pages/drivers/DriverEditPage';
import CustomersPage from './pages/customers/CustomersPage';
import CustomerCreatePage from './pages/customers/CustomerCreatePage';
import CustomerEditPage from './pages/customers/CustomerEditPage';
import ZoneCreatePage from './pages/zones/ZoneCreatePage';
import ZoneEditPage from './pages/zones/ZoneEditPage';
import FleetControlPage from './pages/fleet_control/FleetControlPage';
import LocationsPage from './pages/locations/LocationsPage';
import LocationCreatePage from './pages/locations/LocationCreatePage';
import LocationEditPage from './pages/locations/LocationEditPage';
// import InvoicePage from './pages/invoices/InvoicePage';
import LocationsInventoryPage from './pages/locations/LocationInventoryPage';
import ChargesPage from './pages/charges/ChargesPage';
import PickupInvoicePage from './pages/invoices/PickupInvoicePage';
import PickupInvoiceDetailPage from './pages/invoices/PickupInvoiceDetailPage';
import NotificationDetailPage from './pages/notifications/NotificationDetailPage';
import SettingsPage from './pages/settings/SettingsPage';
import PriceTiersPage from './pages/price_tiers/PriceTiersPage';
import PriceTierCreatePage from './pages/price_tiers/PriceTierCreatePage';
import PriceTierEditPage from './pages/price_tiers/PriceTierEditPage';

function App() {
    return (
        <BrowserRouter>
            <AuthProvider>
                <Routes>
                    <Route path="/" element={<CommonLayout />} >
                        <Route index element={<HomePage />} />
                        <Route path="about" element={<AboutPage />} />
                        <Route path="tracking/:id/" element={<TrackingPublicPage />} />
                    </Route>

                    <Route path="/users" element={<CommonLayout />} >
                        <Route path="login" element={
                            <VerifyLogin>
                                <LoginPage />
                            </VerifyLogin>
                        }
                        />

                        <Route path="register" element={
                            <VerifyLogin>
                                <RegisterPage />
                            </VerifyLogin>
                        }
                        />

                        <Route path="register/step-2" element={
                            <VerifyLogin>
                                <RegisterPageStep2 />
                            </VerifyLogin>
                        }
                        />

                        <Route path="welcome" element={
                            <VerifyLogin>
                                <WelcomePage />
                            </VerifyLogin>
                        }
                        />

                    </Route>

                    <Route path="/admin" element={<AdminLayout />}>
                        <Route path="profile" element={
                            <Protected>
                                <ProfilePage />
                            </Protected>
                        }
                        />
                        <Route path="dashboard" element={
                            <Protected>
                                <DashboardPage />
                            </Protected>
                        }
                        />

                        <Route path="users" element={
                            <Protected>
                                <UserPage />
                            </Protected>
                        }
                        />

                        <Route path="bols" element={
                            <Protected>
                                <BolsPage />
                            </Protected>
                        }
                        />
                        <Route path="bols/:id/" element={
                            <Protected>
                                <BolDetailPage />
                            </Protected>
                        }
                        />
                        <Route
                            path="customers"
                            element={
                                <Protected>
                                    <CustomersPage />
                                </Protected>
                            }
                        />
                        <Route
                            path="customers/create"
                            element={
                                <Protected>
                                    <CustomerCreatePage />
                                </Protected>
                            }
                        />
                        <Route path="customers/:id/" element={
                            <Protected>
                                <CustomerEditPage />
                            </Protected>
                        }
                        />
                        <Route
                            path="loading"
                            element={
                                <Protected>
                                    <ComingSoonPage />
                                </Protected>
                            }
                        />
                        <Route
                            path="grab-jobs"
                            element={
                                <Protected>
                                    <ComingSoonPage />
                                </Protected>
                            }
                        />
                        <Route
                            path="reports"
                            element={
                                <Protected>
                                    <ComingSoonPage />
                                </Protected>
                            }
                        />
                        <Route
                            path="shipments"
                            element={
                                <Protected>
                                    <ComingSoonPage />
                                </Protected>
                            }
                        />
                        <Route
                            path="proforma-invoice"
                            element={
                                <Protected>
                                    <ComingSoonPage />
                                </Protected>
                            }
                        />
                        <Route
                            path="manage-users"
                            element={
                                <Protected>
                                    <ComingSoonPage />
                                </Protected>
                            }
                        />
                        <Route
                            path="profile"
                            element={
                                <Protected>
                                    <ComingSoonPage />
                                </Protected>
                            }
                        />
                        <Route
                            path="pickup-requests"
                            element={
                                <Protected>
                                    <PickupRequestPage />
                                </Protected>
                            }
                        />
                        <Route path="pickup-requests/:id/" element={
                            <Protected>
                                <PickupRequestDetailPage />
                            </Protected>
                        }
                        />
                        <Route path="drivers" element={
                            <Protected>
                                <DriversPage />
                            </Protected>
                        }
                        />
                        <Route path="drivers/create" element={
                            <Protected>
                                <DriverCreatePage />
                            </Protected>
                        }
                        />
                        <Route path="drivers/:id/" element={
                            <Protected>
                                <DriverEditPage />
                            </Protected>
                        }
                        />
                        <Route path="trucks" element={
                            <Protected>
                                <TrucksPage />
                            </Protected>
                        }
                        />
                        <Route path="trucks/create" element={
                            <Protected>
                                <TruckCreatePage />
                            </Protected>
                        }
                        />
                        <Route path="trucks/:id/" element={
                            <Protected>
                                <TruckEditPage />
                            </Protected>
                        }
                        />
                        <Route path="truck-features" element={
                            <Protected>
                                <TruckFeaturesPage />
                            </Protected>
                        }
                        />
                        <Route path="truck-features/create" element={
                            <Protected>
                                <TruckFeatureCreatePage />
                            </Protected>
                        }
                        />
                        <Route path="truck-features/:id/" element={
                            <Protected>
                                <TruckFeatureEditPage />
                            </Protected>
                        }
                        />
                        <Route path="zones" element={
                            <Protected>
                                <ZonesPage />
                            </Protected>
                        }
                        />
                        <Route path="zones/create" element={
                            <Protected>
                                <ZoneCreatePage />
                            </Protected>
                        }
                        />
                        <Route path="zones/:id/" element={
                            <Protected>
                                <ZoneEditPage />
                            </Protected>
                        }
                        />
                        <Route path="fleet-control" element={
                            <Protected>
                                <FleetControlPage />
                            </Protected>
                        }
                        />
                        <Route path="locations" element={
                            <Protected>
                                <LocationsPage />
                            </Protected>
                        }
                        />
                        <Route path="locations/create" element={
                            <Protected>
                                <LocationCreatePage />
                            </Protected>
                        }
                        />
                        <Route path="locations/:id/" element={
                            <Protected>
                                <LocationEditPage />
                            </Protected>
                        }
                        />
                        <Route path="locations/inventory/:id/" element={
                            <Protected>
                                <LocationsInventoryPage />
                            </Protected>
                        }
                        />
                        <Route path="invoices" element={
                            <Protected>
                                <PickupInvoicePage />
                            </Protected>
                        }
                        />
                        <Route path="invoices/:id/" element={
                            <Protected>
                                <PickupInvoiceDetailPage />
                            </Protected>
                        }
                        />
                        <Route path="charges" element={
                            <Protected>
                                <ChargesPage />
                            </Protected>
                        }
                        />
                        <Route path="notifications/:id/" element={
                            <Protected>
                                <NotificationDetailPage />
                            </Protected>
                        }
                        />
                        <Route path="settings" element={
                            <Protected>
                                <SettingsPage />
                            </Protected>
                        }
                        />
                        <Route path="price-tiers" element={
                            <Protected>
                                <PriceTiersPage />
                            </Protected>
                        }
                        />
                        <Route path="price-tiers/create" element={
                            <Protected>
                                <PriceTierCreatePage />
                            </Protected>
                        }
                        />
                        <Route path="price-tiers/:id/" element={
                            <Protected>
                                <PriceTierEditPage />
                            </Protected>
                        }
                        />
                    </Route>
                </Routes>
            </AuthProvider>
        </BrowserRouter>
    );
}

export default App;