import React, { useEffect, useState } from "react";

const GroupPagination = ({ currentPage, totalPages, groupName = 'group_1', updatePagination }) => {
    const [pages, setPages] = useState([]);

    useEffect(() => {
        let pages = [];
        if (totalPages <= 5) {
            // If total pages are less than or equal to 5, show all pages
            for (let i = 1; i <= totalPages; i++) {
                pages.push(i);
            }
        } else {
            // Show first few pages, ellipsis, and last few pages
            if (currentPage <= 3) {
                // Case 1: If current page is in the first 3, show 1, 2, 3, 4, ..., last page
                pages = [1, 2, 3, 4, '...', totalPages];
            } else if (currentPage > 3 && currentPage < totalPages - 2) {
                // Case 2: Show first page, ..., current page - 1, current page, current page + 1, ..., last page
                pages = [1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages];
            } else {
                // Case 3: If current page is near the last few pages, show first page, ..., second last, last
                pages = [1, '...', totalPages - 3, totalPages - 2, totalPages - 1, totalPages];
            }
        }
        setPages(pages)
    }, [totalPages])

    const handlePageChange = (page, totalPages) => {
        updatePagination(page, totalPages, groupName);
    };


    return (
        <div className="pagination-controls border inline-flex overflow-hidden rounded-[16px]">
            {/* Previous Button */}
            <button className={`${currentPage === 1 ? 'bg-gray-400 px-3 py-1  text-white' : 'bg-bg-primary px-3 py-1  text-white'}`} onClick={() => handlePageChange('prev', totalPages)} disabled={currentPage === 1}>
                Previous
            </button>

            {/* Page Numbers */}
            {pages.map((page, index) =>
                page === '...' ? (
                    <span key={index}>...</span>
                ) : (
                    <button
                        key={index}
                        onClick={() => handlePageChange(page, totalPages)}
                        className={currentPage === page ? 'active text-bg-primary border bg-white px-2 font-bold py-1 border-t-0 border-b-0' : 'border border-t-0 border-b-0 bg-white px-2 py-1'}
                    >
                        {page}
                    </button>
                )
            )}

            {/* Next Button */}
            <button className={`${currentPage >= totalPages ? 'bg-gray-400 px-3 py-1  text-white' : 'bg-bg-primary px-3 py-1  text-white'}`} onClick={() => handlePageChange('next', totalPages)} disabled={currentPage >= totalPages}>
                Next
            </button>
        </div>
    );

}
export default GroupPagination;