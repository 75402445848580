import React, { useState, useEffect, useContext } from 'react';
import PageHeading from "../../components/PageHeading";

import SortableTable from "../../components/SortableTable";
import { AuthContext } from '../../context/authContext';

import { Link } from 'react-router-dom'
import Pagination from "../../components/Pagination";
import searchIcon from '../../assets/search-icon.svg';
import xlsxIcon from '../../assets/images/icons/xlsx-icon.webp';
import pdfIcon from '../../assets/images/icons/pdf-official-icon.jpg';
import Loader from '../../components/Loader';
import GraphQuery from '../../components/graphql/queries/Query';
import GraphMutation from '../../components/graphql/mutations/Mutation';
import { gql, useMutation } from '@apollo/client';
import DatePickerComponent from './components/DatePickerComponent';
import AmountPickerComponent from './components/AmountPickerComponent';
import Swal from 'sweetalert2'
import GroupPagination from '../../components/pickup_request_components/GroupPagination';

function PickupInvoicePage({ id = null }) {

    const urlParams = new URLSearchParams(window.location.search);
    const [invoiceData, setInvoiceData] = useState([]);
    const { token } = useContext(AuthContext);
    const [search, setSearch] = useState("");
    const [filter, setFilter] = useState({
        date: { startDate: '', endDate: '' },
        amount: '',
        status: 'new',
        customer: id || '',
    });
    const [selectedRows, setSelectedRows] = useState([]);
    const [showExportOption, setShowExportOption] = useState(false);
    const [statusFilterOptions, setStatusFilterOptions] = useState([
        { title: 'New (Open)', slug: 'new' },
        { title: 'Unpaid', slug: 'unpaid' },
        { title: 'Cancelled', slug: 'cancelled' },
        { title: 'Partially Paid', slug: 'partially-paid' },
        { title: 'Refunded', slug: 'refunded' },
    ])

    const pageValue = urlParams.get('page');
    const [page, setPage] = useState(pageValue ? parseInt(pageValue) : 1);
    const [customerOptions, setCustomerOptions] = useState([]);
    const [pickupInvoicesLoading, setPickupInvoicesLoading] = useState(false);

    const recordsPerPage = 10;
    const [offset, setOffset] = useState((page - 1) * recordsPerPage);

    const CREATE_PICKUP_INVOICE_EXCEL = gql`
        mutation($pickupInvoiceExcelNew: CreatePickupInvoiceExcelInput!) {
            createPickupInvoiceExcel(pickupInvoiceExcelData: $pickupInvoiceExcelNew) {
                status
                message
                file
            }
        }
    `;

    const EXPORT_PICKUP_INVOICE_STATEMENT = gql`
        mutation($invoices: [String]!, $fileType: String! ) {
            exportPickupInvoiceStatement(invoices: $invoices, fileType: $fileType) {
                success
                message
                filePath
            }
        }
    `

    const { executeMutation: CreatePickupInvoices, responseData: pickupInvoiceData, loading: pickupInvoiceLoading, errors: pickupInvoiceError } = GraphMutation(
        { name: 'PickupInvoices', expectedResponseField: 'pickupInvoices', method: 'create' },
        { name: 'pickupInvoice', type: 'CreatePickupInvoicesInput' },
        `
            id
            invoiceDate,
            invoicePath,
            status,
            subtotal,
            totalDiscount,
            totalPrice,
            pickupRequest {
                id
                pickupNumber
            }
            customer {
                contactPersonFullName
            }
        `
    );

    const { responseData: customerData, refetch: refetchCustomers } = GraphQuery({ name: 'Customers', single: 'Customer' }, {
        // first: 10,
        skip: 0,
        search: '',
        sortBy: 'contact_person_email',
        order: 'asc',
    }, `
        id
        businessName,
        contactPersonFullName
    `);


    const [createPickupInvoiceExcel, { loading: pickupInvoiceExcelLoading, data: pickupInvoiceExcelData }] = useMutation(CREATE_PICKUP_INVOICE_EXCEL, {
        onError: (error) => {
            // try {
            //     const parsedError = JSON.parse(error.message);
            //     setErrors(parsedError);
            // } catch (parseError) {
            //     setErrors('An error occurred while processing your request.');
            // }

        },
        onCompleted: (data) => {
            console.log('completed', data)
            const fileUrl = data.createPickupInvoiceExcel.file;
            if (fileUrl) {
                fetch(fileUrl)
                    .then(response => response.blob())
                    .then(blob => {
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = 'pickup_invoice.xlsx';
                        document.body.appendChild(a);
                        a.click();
                        a.remove();
                        window.URL.revokeObjectURL(url);
                    })
                    .catch(error => console.error('Error downloading the file:', error));
            }
            // downloading data.createPickupInvoiceExcel.file
        }
    })

    const [exportPickupInvoiceStatement, { loading: pickupInvoiceStatementLoading, data: pickupInvoiceStatementData }] = useMutation(EXPORT_PICKUP_INVOICE_STATEMENT, {
        onError: (error) => {
            // try {
            //     const parsedError = JSON.parse(error.message);
            //     setErrors(parsedError);
            // } catch (parseError) {
            //     setErrors('An error occurred while processing your request.');
            // }

        },
        onCompleted: (data) => {
            console.log('completed', data)
            const fileUrl = data.exportPickupInvoiceStatement.filePath;
            if (fileUrl) {
                fetch(fileUrl)
                    .then(response => response.blob())
                    .then(blob => {
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.href = url;
                        if (fileUrl.endsWith('.pdf')) {
                            a.download = 'pickup_invoice_statement.pdf';
                        } else {
                            a.download = 'pickup_invoice_statement.xlsx';
                        }
                        document.body.appendChild(a);
                        a.click();
                        a.remove();
                        window.URL.revokeObjectURL(url);
                    })
                    .catch(error => console.error('Error downloading the file:', error));
            }
            // downloading data.createPickupInvoiceExcel.file
        }
    })

    const { responseData: pickupInoicesData, loading, refetchData } = GraphQuery({ name: 'PickupInvoices', single: 'PickupInvoice' }, {
        // first: 10,
        skip: 0,
        search: search.length > 3 ? search : '',
        sortBy: 'id',
        order: 'asc',
        amount: '',
        startDate: '',
        endDate: '',
        status: 'new',
        customer: '',
    }, `
        id
        invoiceDate,
        invoicePath,
        status,
        subtotal,
        totalDiscount,
        totalPrice,
        pickupRequest {
            id
            pickupNumber
        }
        customer {
            contactPersonFullName
        }
    `);

    useEffect(() => {
        if (pickupInvoiceData) {
            console.log('pickup invoice data', pickupInvoiceData)
            if (pickupInvoiceData) {
                // Create a map of pickupInvoiceData for quick lookup
                const pickupInvoiceMap = new Map(pickupInvoiceData.map(invoice => [invoice.id, invoice]));

                // Replace old invoice data with new data based on matching IDs
                const updatedInvoiceData = invoiceData.map(invoice =>
                    pickupInvoiceMap.has(invoice.id) ? pickupInvoiceMap.get(invoice.id) : invoice
                );

                setInvoiceData(updatedInvoiceData);
            }
        }

    }, [pickupInvoiceData]);

    useEffect(() => {
        if (customerData) {
            // const customers = customerData.customers;
            setCustomerOptions(customerData);
        }
    }, [customerData, setCustomerOptions]);

    useEffect(() => {
        if (pickupInoicesData) {
            setPickupInvoicesLoading(false);
            setInvoiceData(pickupInoicesData)
        }
    }, [pickupInoicesData, setInvoiceData]);

    const [count, setCount] = useState(0);

    useEffect(() => {
        setPage(pageValue ? parseInt(pageValue) : 1);
    }, [pageValue]);

    useEffect(() => {
        if (filter != null) {
            console.log('data', filter);
            console.log('=======================');
            refetchData({
                amount: filter.amount ? filter.amount.toString() : '',
                status: filter?.status,
                startDate: filter?.date?.startDate,
                endDate: filter?.date?.endDate,
                first: recordsPerPage,
                search: search.length > 3 ? search : '',
                customer: filter.customer,
            })
        }
    }, [filter])


    const exportExcelFile = async (invoice, fileType) => {
        if (fileType === 'xlsx') {
            // await createPickupInvoiceExcel(input);
            createPickupInvoiceExcel({
                context: {
                    headers: {
                        Authorization: `JWT ${token}`
                    }
                },
                variables: {
                    pickupInvoiceExcelNew: {
                        invoiceId: invoice.id,
                    }
                }
            });
        }
        else {
            if (invoice.invoicePath) {
                fetch(invoice.invoicePath)
                    .then(response => response.blob())
                    .then(blob => {
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = 'pickup_invoice.pdf';
                        document.body.appendChild(a);
                        a.click();
                        a.remove();
                        window.URL.revokeObjectURL(url);
                    })
                    .catch(error => console.error('Error downloading the file:', error));
            }
        }
    }

    const handlePageChange = (page) => {
        setPage(page);
        setOffset((page - 1) * recordsPerPage);
    }

    const selectRowHandle = (e) => {
        console.log('selected box', e)

        setSelectedRows(previousSelectedRows => {
            const rowIndex = previousSelectedRows.findIndex(r => r === e.target.value);
            if (rowIndex === -1) {
                return [...previousSelectedRows, e.target.value];
            }
            return previousSelectedRows.filter(r => r !== e.target.value);
        });
    }
    const checkAllRowsSelected = () => {
        return invoiceData.every(invoice => selectedRows.includes(invoice.id)) ? true : false;
    }

    const selectAllRowsHandle = () => {
        let updatedRows = [];
        let allSelectedCheck = checkAllRowsSelected()
        console.log('allSelectedCheck', allSelectedCheck)
        if (allSelectedCheck == false) {
            updatedRows = invoiceData.map((invoice) => invoice.id);
        }
        setSelectedRows(updatedRows);
        console.log('updated rows', updatedRows)
    }

    const config = [
        {
            label: 'Select All',
            labelRender: (label) => {
                return (
                    <div>
                        <label className="relative flex items-center p-3 rounded-full cursor-pointer" htmlFor={'check_invoice_all'}>
                            <input onChange={selectAllRowsHandle} checked={checkAllRowsSelected()} type="checkbox"
                                className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-gray-900 checked:before:bg-gray-900 hover:before:opacity-10"
                                id={'check_invoice_all'} />
                            <span
                                className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor"
                                    stroke="currentColor" strokeWidth="1">
                                    <path fillRule="evenodd"
                                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                        clipRule="evenodd"></path>
                                </svg>
                            </span>
                        </label>
                    </div>
                )
            },
            render: (invoice) => {
                return (
                    <div>
                        <label className="relative flex items-center p-3 rounded-full cursor-pointer" htmlFor={'check_invoice' + invoice.id}>
                            <input name={selectedRows} onChange={selectRowHandle} value={invoice.id} checked={selectedRows.includes(invoice.id)} type="checkbox"
                                className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-gray-900 checked:before:bg-gray-900 hover:before:opacity-10"
                                id={'check_invoice' + invoice.id} />
                            <span
                                className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-full peer-checked:opacity-100">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor"
                                    stroke="currentColor" strokeWidth="1">
                                    <path fillRule="evenodd"
                                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                        clipRule="evenodd"></path>
                                </svg>
                            </span>
                        </label>
                    </div>
                )
            },
            headerClassName: 'pr-8 w-12',
            rowCellClassName: '',
        },
        {
            label: 'ID',
            render: (invoice) => <Link
                to={"/admin/invoices/" + invoice.id}
                className='text-blue-600 text-sm leading-5 font-normal'
            >{invoice.id}</Link>,
            headerClassName: 'pr-8 w-12',
            rowCellClassName: '',
        },
        {
            label: 'Invoice Date',
            render: (invoice) => invoice.invoiceDate,
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4',
        },
        {
            label: 'Pickup Number',
            render: (invoice) => invoice?.pickupRequest?.pickupNumber,
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4',
        },
        {
            label: 'Customer',
            render: (invoice) => invoice?.customer?.contactPersonFullName,
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4 capitalize',
        },
        {
            label: 'Status',
            render: (invoice) => { return (<span className='p-1 px-3 bg-yellow-300 rounded-md'>{invoice.status}</span>) },
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4 capitalize',
        },
        {
            label: 'invoice file',
            render: (invoice) => <Link
                to={invoice.invoicePath}
                className='text-blue-600 text-sm leading-5 font-normal'
            >{invoice.invoicePath}</Link>,
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4',
        },
        {
            label: 'Subtotal',
            render: (invoice) => '$' + (parseFloat(invoice.subtotal) > 0 ? parseFloat(invoice.subtotal).toFixed(2) : '0.00'),
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4',
        },
        {
            label: 'Total Discount',
            render: (invoice) => '$' + (invoice.totalDiscount > 0 ? parseFloat(invoice.totalDiscount).toFixed(2) : '0.00'),
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4',
        },
        {
            label: 'Total Price',
            render: (invoice) => '$' + parseFloat(invoice.totalPrice).toFixed(2),
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4',
        },
        {
            label: 'Export',
            render: (invoice) => {
                return (
                    <div className='flex items-center'>
                        <button
                            onClick={() => exportExcelFile(invoice, 'xlsx')}
                            className='text-blue-600 text-sm leading-5 font-normal'
                        >
                            <img src={xlsxIcon} width={40} height={40} />
                        </button>
                        <button
                            onClick={() => exportExcelFile(invoice, 'pdf')}
                            className='text-blue-600 text-sm leading-5 font-normal ml-2'
                        >
                            <img src={pdfIcon} width={40} height={40} />
                        </button>
                    </div>
                )
            },
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4',
        },
    ];



    const keyFun = (invoice) => {
        return invoice.id
    }

    const searchHandler = (e) => {
        e.preventDefault();
        setSearch(e.target.value);
    }

    const filterHandle = (property, value) => {
        console.log(property, ': ', value);
        setFilter({
            ...filter,
            [property]: value,
        });

    }

    const exportHandle = (type) => {
        console.log('selected rows', selectedRows, 'file', type);
        setShowExportOption(!showExportOption);
        if (selectedRows.length > 0) {
            exportPickupInvoiceStatement({
                context: {
                    headers: {
                        Authorization: `JWT ${token}`
                    }
                },
                variables: {
                    invoices: selectedRows,
                    fileType: type,
                }
            })
        }
        else {
            Swal.fire('Select at least 1 row to export', '', 'error');
        }
    }

    const regenerateInvoiceHandle = async () => {
        let input = {
            ids: selectedRows,
        };
        setPickupInvoicesLoading(true);
        await CreatePickupInvoices(input);
    }

    return (
        <div>
            <div className="content px-3 lg:px-8">
                <div className="content-bar flex justify-between mt-6">
                    <div className='flex flex-wrap justify-between'>
                        <div className="mr-3">
                            <PageHeading>{id == null ? 'INVOICES' : 'CUSTOMER INVOICES'}</PageHeading>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content px-3 lg:px-8">
                <div className="content-bar mt-6">
                    <div className="search-bar w-full sm:w-2/6">
                        <div className="search-field-container flex p-2 pl-4 bg-white border border-gray-300 shadow-sm rounded-md">
                            <img className="pr-2" src={searchIcon} alt="Search Icon" />
                            <input value={search} onInput={(e) => searchHandler(e)} type="text" className="pl-1 w-full" placeholder="Business Name or Pickup Number" />
                        </div>
                    </div>
                    <div className='mt-3 flex gap-3'>
                        <DatePickerComponent getUpdatedDateRange={(date) => filterHandle('date', { startDate: date.startDate, endDate: date.endDate })} />
                        <AmountPickerComponent amountFilterValue={filter.amount} updateAmountFilterValue={(value) => filterHandle('amount', value)} />
                        <div className='relative'>
                            {pickupInvoiceStatementLoading ? 'Exporting file ....' : <button onClick={() => setShowExportOption(!showExportOption)} className="rounded-md border bg-white px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-black-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 flex items-center">
                                <svg className='mr-2' width={24} height={24} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                    <path d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 128-168 0c-13.3 0-24 10.7-24 24s10.7 24 24 24l168 0 0 112c0 35.3-28.7 64-64 64L64 512c-35.3 0-64-28.7-64-64L0 64zM384 336l0-48 110.1 0-39-39c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l80 80c9.4 9.4 9.4 24.6 0 33.9l-80 80c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l39-39L384 336zm0-208l-128 0L256 0 384 128z" />
                                </svg>
                                Export Statement
                            </button>}
                            {showExportOption && <div className="absolute top-[45px] z-10 rounded-md border overflow-hidden w-[150px]">
                                <div className="p-o bg-white">
                                    <div onClick={() => exportHandle('pdf')} className='hover:bg-slate-400 p-3'>Export PDF</div>
                                    <div onClick={() => exportHandle('excel')} className='hover:bg-slate-400 p-3'>Export Excel</div>
                                </div>
                            </div>}
                        </div>
                        <div className="relative mb-3 sm:mb-0">
                            <select value={filter.status} onChange={(e) => filterHandle('status', e.target.value)} className="block appearance-none w-full bg-grey-lighter border border-grey-lighter text-grey-darker py-2 px-4 pr-8 rounded" id="invoice_status_filter">
                                <option value={null}>Select Status</option>
                                {
                                    statusFilterOptions.map((option, index) => {
                                        return (
                                            <option key={index} value={option.slug}>{option.title}</option>
                                        );
                                    })
                                }
                            </select>
                            <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker right-0 top-4">
                                <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                            </div>
                        </div>
                        {id === null && <div className="relative mb-3 sm:mb-0">
                            <select value={filter.customer} onChange={(e) => filterHandle('customer', e.target.value)} className="block appearance-none w-full bg-grey-lighter border border-grey-lighter text-grey-darker py-2 px-4 pr-8 rounded" id="invoice_customer_filter">
                                <option value={''}>Select Customer</option>
                                {
                                    customerOptions.map((option, index) => {
                                        return (
                                            <option key={index} value={option.id}>{option.contactPersonFullName}</option>
                                        );
                                    })
                                }
                            </select>
                            <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker right-0 top-4">
                                <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                            </div>
                        </div>}

                        {selectedRows.length > 0 && <button disabled={pickupInvoiceLoading} onClick={() => regenerateInvoiceHandle()} className="rounded-md border bg-white px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-black-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 flex items-center">
                            {pickupInvoiceLoading ? 'Loading...' : 'Regenerate Invoice'}
                        </button>}
                        {/* <button onClick={() => selectAllRowsHandle()} className="rounded-md border bg-white px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-black-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 flex items-center">
                            {checkAllRowsSelected() ? 'Deselect All' : 'Select All'}
                        </button> */}
                    </div>
                </div>
                {loading || pickupInvoiceLoading ? <div>
                    <Loader />
                </div> :
                    <div className="mt-8">
                        <div className="not-prose relative bg-gray-50 rounded-xl overflow-hidden">
                            <div className="absolute inset-0"></div>
                            <div className="relative rounded-xl overflow-auto">
                                <div className="shadow-sm overflow-x-scroll mt-7">
                                    <SortableTable tableLayout="row" data={invoiceData} config={config} keyFun={keyFun} />
                                </div>
                            </div>
                            <div className="absolute inset-0 pointer-events-none border border-black/1 rounded-xl"></div>
                        </div>
                        <div className="flex justify-between items-center self-stretch py-6">
                            {/* {count > 0 ? <div>Showing {offset + 1} to {(page) * recordsPerPage} of {count} results</div> : null}
                            {count > recordsPerPage ?
                                <div className="flex">
                                    <Pagination
                                        to="/bols"
                                        currentPage={page}
                                        totalPages={Math.ceil(count / recordsPerPage)}
                                        onPageChange={handlePageChange}
                                    />
                                </div>
                                : null} */}
                            {/* {totalPages > 1 && <div className='mt-4 flex justify-end'>
                                <GroupPagination
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    updatePagination={updatePaginationHandle}
                                />
                            </div>} */}
                        </div>
                    </div>}
            </div>



        </div >
    );
}

export default PickupInvoicePage;