import { useEffect, useState } from "react";
import GraphQuery from "../../components/graphql/queries/Query";
import PageHeading from "../../components/PageHeading";
import Switch from "react-switch";
import GraphMutation from "../../components/graphql/mutations/Mutation";
import Swal from 'sweetalert2'


function SettingsPage() {
    const [search, setSearch] = useState('');
    const [settings, setSettings] = useState([]);

    const { responseData: settingsData, loading, error } = GraphQuery({ name: 'Settings', single: 'Setting' }, {
        skip: 0,
        search: search.length > 3 ? search : '',
        sortBy: 'id',
        order: 'asc',
    }, 'id,settingType,optionField,optionValue,userSetting{id,value}');

    const { executeMutation: updateSetting, responseData: updatedSettingData, loading: updatedSettingLoading, errors: updatedSettingError } = GraphMutation(
        { name: 'Setting', expectedResponseField: 'setting', method: 'update' },
        { name: 'setting', type: 'UpdateSettingInput' },
        `
            id,
            settingType,
            optionField,
            optionValue,
            userSetting {
                id,
                value
            },
        `
    );

    useEffect(() => {
        if (settingsData) {
            setSettings(settingsData)
        }
    }, [settingsData])

    useEffect(() => {
        console.log(updatedSettingData, 'Updated setting data');
        if (updatedSettingData) {
            let settingData = [...settings];
            let indexOfSetting = settingData.indexOf(setting => setting.id == updatedSettingData.id);
            if (indexOfSetting !== -1) {
                settingData[indexOfSetting] = { ...settingData[indexOfSetting], setting: updatedSettingData.userSetting }
            }
            setSettings(settingsData)
            Swal.fire({
                title: "Success!",
                text: "Setting Updated Successfully!",
                icon: "success",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.onmouseenter = Swal.stopTimer;
                    toast.onmouseleave = Swal.resumeTimer;
                }
            });
        }
    }, [updatedSettingData])

    const slugToName = (slug = '') => {
        let name = slug?.replaceAll('_', " ")
        return name;
    }

    const switchToggleHandle = async (index, option_value) => {
        const updatedSettings = settings.map((setting, idx) =>
            idx === index ? { ...setting, optionValue: option_value } : setting
        );

        setSettings(updatedSettings);
        console.log(settings, index, option_value, 'testing ===> ')
        // e.preventDefault();
        let id = settings[index].id;
        let input = {
            value: option_value.toString()
        }
        await updateSetting(input, parseInt(id));
    }

    const renderSettings = () => {
        return settings.length > 0 ? (
            settings.map((setting, index) => {
                const isBoolean = setting.settingType === 'boolean';
                const userSettingValue = setting.userSetting?.value;

                let settingSelectedValue = isBoolean
                    ? userSettingValue === 'true' || userSettingValue === true || setting.optionValue === true
                    : userSettingValue || setting.optionValue;

                return (
                    <div key={index} className="flex items-center justify-between">
                        <div>
                            <label className="capitalize">{slugToName(setting.optionField)}</label>
                        </div>
                        <div>
                            {isBoolean && (
                                <Switch
                                    checkedIcon={false}
                                    uncheckedIcon={false}
                                    offColor="#eb0303"
                                    onChange={(value) => switchToggleHandle(index, value)}
                                    checked={!!settingSelectedValue} // Ensure boolean value
                                />
                            )}
                        </div>
                    </div>
                );
            })
        ) : null;
    };


    return (
        <div className="content px-3 lg:px-8">
            <div className="content-bar flex justify-between mt-6">
                <PageHeading>Settings</PageHeading>
            </div>

            <div className="mt-8">
                <div className="not-prose relative bg-gray-50 rounded-xl overflow-hidden">
                    <div className="absolute inset-0"></div>
                    <div className="relative rounded-xl overflow-auto p-8">
                        <div className="shadow-sm overflow-hidden ">
                            <h3 className="text-xl font-semibold">General Settings</h3>
                        </div>
                        <div className="mt-6 flex">
                            <div className="bg-white rounded-md px-4 py-3  w-1/2">
                                {renderSettings()}
                            </div>
                        </div>
                    </div>
                    <div className="absolute inset-0 pointer-events-none border border-black/1 rounded-xl"></div>
                </div>
            </div>
        </div>
    );
}

export default SettingsPage;