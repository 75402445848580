import React, { useState } from "react";
import { DateRangePicker } from "react-date-range";
import { enUS } from 'date-fns/locale';

const AmountPickerComponent = ({ amountFilterValue = '', updateAmountFilterValue }) => {
    const [amount, setAmount] = useState(0 || amountFilterValue);

    const [showAmountFilter, setShowAmountFilter] = useState(false);

    const AmountFilterToggle = () => {
        setShowAmountFilter(!showAmountFilter);
    };

    const amountInputHandle = (e) => {
        setAmount(e.target.value)
    }

    const applyAmountFilterHandle = () => {
        console.log(amount);
        setShowAmountFilter(false);
        updateAmountFilterValue(amount);
    }

    return (
        <div className="relative">
            <button onClick={() => AmountFilterToggle()} type="button" className="rounded-md border bg-white px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-black-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <span className="ml-2">Amount Filter</span>
            </button>
            {showAmountFilter && <div className="absolute top-[45px] z-10 rounded-md border overflow-hidden">
                <div className="p-3 bg-white">
                    <label>Amount</label>
                    <input value={amount} onChange={amountInputHandle} className="border py-2 rounded-md px-2" type="number" min={0} placeholder="Amount" />
                    <div className="flex gap-3">
                        <button onClick={() => applyAmountFilterHandle()} className="mt-3 rounded-md border bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-black-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 flex items-center">Apply</button>
                        <button onClick={() => setAmount('')} className="mt-3 rounded-md border bg-red-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-black-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 flex items-center">Reset</button>

                    </div>
                </div>
            </div>}
        </div>
    )
}

export default AmountPickerComponent;