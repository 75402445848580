import React, { useState, useEffect, useContext, useRef } from 'react';
import PageHeading from "../components/PageHeading";

import { gql, useQuery } from '@apollo/client';

import SortableTable from "../components/SortableTable";
import { AuthContext } from '../context/authContext';

import { Link } from 'react-router-dom'
import searchIcon from '../assets/search-icon.svg';
import Loader from '../components/Loader';
import ManualPickupRequest from '../components/Manual Pickup Request/ManualPickupRequest';
import EditTableColumnSettingModal from '../components/table/EditTableColumnSettingModal';
import ReactDateRangePicker from '../components/DateRangePicker';
import GroupPagination from '../components/pickup_request_components/GroupPagination';

const GET_PICKUP_REQUESTS_GROUP_BY_STATUS = gql`
query GetPickupRequestsGroupByStatus($first: Int, $skip: Int, $search: String, $sortBy: PickupRequestSortInput, $pickupStatus: String, $startDate: String, 
        $endDate: String) {
  pickupRequestsGroupByStatus(first: $first, skip: $skip, search: $search, sortBy: $sortBy, pickupStatus: $pickupStatus, startDate: $startDate, 
            endDate: $endDate) {
    categorizedResults {
        status
        requests {
            id,
            createdAt,
            apiMode,
            pickupNumber,
            pickupStatus,
            shipmentTerms,
            driver {
                id,
                user {
                    firstName,
                    lastName
                }
            },
            truck {
                id,
                numberPlate,
                make,
                model,
                color
            }
            account {
                id,
                companyName,
            }
            customer {
                id,
                contactPersonFullName,
                businessName
            }
            pickupRequestAddresses {
                addressType,
                companyName,
                address1,
                city,
                state,
            }
        }
    }
    totalRecords {
      status
      count
    }
  }
}
`


const GET_COLUMN_SETTINGS = gql`
  query GetUserTableColumnSetting($tableName: String!) {
    userTableColumnSetting(tableName: $tableName) {
      columnSettings
    }
  }
`;

function PickupRequestPage() {
    const urlParams = new URLSearchParams(window.location.search);

    const { token } = useContext(AuthContext);
    const [search, setSearch] = useState("");

    const [selectedTab, setSelectedTab] = useState('PickupRequest')
    const tableName = useState('pickupRequest')
    const [groupedByStatus, setGroupedByStatus] = useState([])
    const [pagination, setPagination] = useState({});
    const [totalRecords, setTotalRecords] = useState([]);
    const [dateRange, setDateRange] = useState({});

    const [pickupStatuses, setPickupSatuses] = useState([
        { id: 1, title: 'assigned', value: 'driver-assigned', textColor: 'text-[#000000]', bgColor: 'bg-[#FFF5D7]' },
        { id: 2, title: 'accepted', value: 'driver-accepted', textColor: 'text-[#000000]', bgColor: 'bg-[#8DECB4]' },
        { id: 3, title: 'canceled', value: 'canceled', textColor: 'text-[#000000]', bgColor: 'bg-[#FF7D7D]' },
        { id: 4, title: 'declined', value: 'driver-declined', textColor: 'text-[#000000]', bgColor: 'bg-[#FF748B]' },
        { id: 5, title: 'picked', value: 'picked', textColor: 'text-[#FFFFFF]', bgColor: 'bg-[#FF69B4]' },
        { id: 6, title: 'delivered', value: 'delivered', textColor: 'text-[#000000]', bgColor: 'bg-[#C1F2B0]' },
        { id: 7, title: 'unloaded', value: 'unloaded', textColor: 'text-[#FFFFFF]', bgColor: 'bg-[orange]' },
        { id: 8, title: 'loaded', value: 'loaded', textColor: 'text-[#000000]', bgColor: 'bg-[#20B2AA]' },
        { id: 9, title: 'delivery accepted', value: 'driver-accepted-delivery', textColor: 'text-[#FFFFFF]', bgColor: 'bg-[#185519]' },
        { id: 10, title: 'New Created', value: 'new-created', textColor: 'text-[#000000]', bgColor: 'bg-[#FFF1DB]' },
        { id: 11, title: 'Confirmed', value: 'confirmed', textColor: 'text-[#000000]', bgColor: 'bg-[#C4E1F6]' },
        { id: 12, title: 'pickup on the move', value: 'on-the-move', textColor: 'text-[#000000]', bgColor: 'bg-[#C9E9D2]' },
        { id: 13, title: 'delivery on the move', value: 'delivery-on-the-move', textColor: 'text-[#000000]', bgColor: 'bg-[#257180]' },
        { id: 14, title: 'delivery declined', value: 'driver-declined-delivery', textColor: 'text-[#000000]', bgColor: 'bg-[#C62E2E]' },
    ]);

    const recordsPerPage = 5;

    const { loading, error, data: pickupData, refetch } = useQuery(GET_PICKUP_REQUESTS_GROUP_BY_STATUS, {
        context: {
            headers: {
                Authorization: `JWT ${token}`,
            },
        },
        variables: {
            first: recordsPerPage,
            skip: 0,
            search: search.length > 3 ? search : '',
            sortBy: {
                field: 'id',
                direction: 'desc',
            },
        },
    });

    const { data: columnSettingData } = useQuery(GET_COLUMN_SETTINGS, {
        fetchPolicy: 'network-only',
        context: {
            headers: {
                Authorization: `JWT ${token}`,
            },
        },
        variables: { tableName: tableName }
    });


    const [columns, setColumns] = useState([]);

    useEffect(() => {
        if (columnSettingData?.userTableColumnSetting) {
            let columnSettings = JSON.parse(columnSettingData?.userTableColumnSetting?.columnSettings)
            setColumns(() => columnSettings?.columns);
        }
        else if (columns.length === 0) {
            setColumns([
                { "id": 1, "name": "ID", "slug": "id", "order": 1, "visible": true },
                { "id": 2, "name": "Customer", "slug": "customer", "order": 2, "visible": true },
                { "id": 3, "name": "Shipper Company Name", "slug": "shipper-company-name", "order": 3, "visible": true },
                { "id": 4, "name": "Shipper City", "slug": "shipper-city", "order": 4, "visible": true },
                { "id": 5, "name": "Shipper State", "slug": "shipper-state", "order": 5, "visible": true },
                { "id": 6, "name": "Consignee Company Name", "slug": "consignee-company-name", "order": 6, "visible": true },
                { "id": 7, "name": "Consignee City", "slug": "consignee-city", "order": 7, "visible": true },
                { "id": 8, "name": "Consignee State", "slug": "consignee-state", "order": 8, "visible": true },
                { "id": 9, "name": "Billing Company Name", "slug": "billing-company-name", "order": 9, "visible": true },
                { "id": 10, "name": "Billing City", "slug": "billing-city", "order": 10, "visible": true },
                { "id": 11, "name": "Billing State", "slug": "billing-state", "order": 11, "visible": true },
                { "id": 12, "name": "Pickup Number", "slug": "pickup-number", "order": 12, "visible": true },
                { "id": 14, "name": "Assigned Driver", "slug": "assigned-driver", "order": 14, "visible": true },
                { "id": 15, "name": "Assigned Truck", "slug": "assigned-truck", "order": 15, "visible": true },
                { "id": 16, "name": "Status", "slug": "pickup-status", "order": 16, "visible": true },
                { "id": 17, "name": "Shipment Terms", "slug": "shipment-terms", "order": 17, "visible": true },
                { "id": 18, "name": "Created At", "slug": "created-at", "order": 18, "visible": true },
                { "id": 19, "name": "Api Mode", "slug": "api-mode", "order": 19, "visible": true }
            ]);
        }
    }, [columnSettingData]);


    const [data, setData] = useState([]);

    const fetchPickupRequests = async () => {
        if (pickupData && pickupData.pickupRequestsGroupByStatus) {
            const pickupRequestGroups = await pickupData?.pickupRequestsGroupByStatus?.categorizedResults;
            const groupByStatusTotalRecords = await pickupData?.pickupRequestsGroupByStatus?.totalRecords;

            if (groupedByStatus.length > 0) {
                await setGroupedByStatus((prevGroupedByStatus) => {
                    let updatedGroupedByStatus = [...prevGroupedByStatus];
                    pickupRequestGroups.forEach((newGroup) => {
                        const existingIndex = updatedGroupedByStatus.findIndex(
                            (group) => group.status === newGroup.status
                        );
                        if (existingIndex !== -1) {
                            updatedGroupedByStatus[existingIndex] = newGroup;
                        }

                    })
                    return updatedGroupedByStatus;
                })
            }
            else {
                setGroupedByStatus(pickupRequestGroups)
            }
            if (totalRecords.length > 0) {
                await setTotalRecords((prevRecords) => {
                    let updatedTotalRecords = [...prevRecords];
                    groupByStatusTotalRecords.forEach(newRecord => {
                        const existingIndex = updatedTotalRecords.findIndex(record => record.status === newRecord.status)

                        if (existingIndex !== -1) {
                            updatedTotalRecords[existingIndex] = newRecord;
                        }
                    });
                    return updatedTotalRecords;
                });
            }
            else {
                setTotalRecords(groupByStatusTotalRecords);
            }
        }
    }

    useEffect(() => {
        fetchPickupRequests();
    }, [pickupData, setData]);

    if (error) {
        return <p>Error :(</p>;
    }

    const updatePaginationHandle = (page, totalPages, status) => {

        let newPage;

        if (page === 'next') {
            // For 'next' button
            newPage = pagination[status] ? pagination[status] + 1 : 2;
        } else if (page === 'prev') {
            // For 'previous' button
            newPage = pagination[status] ? pagination[status] - 1 : 2;
        } else {
            // For direct page number click
            newPage = page;
        }

        // Ensure the newPage is within the correct bounds
        newPage = Math.max(1, Math.min(newPage, totalPages));

        setPagination({
            ...pagination,
            [status]: newPage
        });

        const skip = (newPage - 1) * recordsPerPage;
        refetch({
            first: recordsPerPage,
            skip: skip,
            search: search.length > 3 ? search : '',
            pickupStatus: status // Filter by the specific status group
        });
    }

    const originalConfig = [
        {
            label: 'ID',
            slug: 'id',
            render: (pickup) => <Link to={`/admin/pickup-requests/${pickup.id}`} className='text-blue-600 text-sm leading-5 font-normal'>{pickup.id}</Link>,
            headerClassName: 'pr-8 w-12',
            rowCellClassName: '',
            sortValue: (pickup) => pickup.id, // Sort by ID
        },
        {
            label: 'Pickup Number',
            slug: 'pickup-number',
            render: (pickup) => pickup.pickupNumber,
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4',
            sortValue: (pickup) => pickup.pickupNumber, // Sort by pickup number
        },
        {
            label: 'Customer',
            slug: 'customer',
            render: (pickup) => pickup.customer[0]['businessName'],
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4',
            sortValue: (pickup) => pickup.customer[0]['businessName'], // Sort by customer name
        },
        {
            label: 'Shipper Company Name',
            slug: 'shipper-company-name',
            render: (pickup) => {
                let sourceAddress = pickup?.pickupRequestAddresses?.filter(address => address.addressType === 'SOURCE');
                return sourceAddress[0]?.companyName;
            },
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4',
            sortValue: (pickup) => {
                let sourceAddress = pickup?.pickupRequestAddresses?.filter(address => address.addressType === 'SOURCE');
                return sourceAddress[0]?.companyName; // Sort by shipper company name
            },
        },
        {
            label: 'Shipper State',
            slug: 'shipper-state',
            render: (pickup) => {
                let sourceAddress = pickup?.pickupRequestAddresses?.filter(address => address.addressType === 'SOURCE');
                return sourceAddress[0]?.state;
            },
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4',
            sortValue: (pickup) => {
                let sourceAddress = pickup?.pickupRequestAddresses?.filter(address => address.addressType === 'SOURCE');
                return sourceAddress[0]?.state; // Sort by shipper state
            },
        },
        {
            label: 'Shipper City',
            slug: 'shipper-city',
            render: (pickup) => {
                let sourceAddress = pickup?.pickupRequestAddresses?.filter(address => address.addressType === 'SOURCE');
                return sourceAddress[0]?.city;
            },
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4',
            sortValue: (pickup) => {
                let sourceAddress = pickup?.pickupRequestAddresses?.filter(address => address.addressType === 'SOURCE');
                return sourceAddress[0]?.city; // Sort by shipper city
            },
        },
        {
            label: 'Consignee Company Name',
            slug: 'consignee-company-name',
            render: (pickup) => {
                let sourceAddress = pickup?.pickupRequestAddresses?.filter(address => address.addressType === 'DESTINATION');
                return sourceAddress[0]?.companyName;
            },
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4',
            sortValue: (pickup) => {
                let sourceAddress = pickup?.pickupRequestAddresses?.filter(address => address.addressType === 'DESTINATION');
                return sourceAddress[0]?.companyName; // Sort by consignee company name
            },
        },
        {
            label: 'Consignee State',
            slug: 'consignee-state',
            render: (pickup) => {
                let sourceAddress = pickup?.pickupRequestAddresses?.filter(address => address.addressType === 'DESTINATION');
                return sourceAddress[0]?.state;
            },
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4',
            sortValue: (pickup) => {
                let sourceAddress = pickup?.pickupRequestAddresses?.filter(address => address.addressType === 'DESTINATION');
                return sourceAddress[0]?.state; // Sort by consignee state
            },
        },
        {
            label: 'Consignee City',
            slug: 'consignee-city',
            render: (pickup) => {
                let sourceAddress = pickup?.pickupRequestAddresses?.filter(address => address.addressType === 'DESTINATION');
                return sourceAddress[0]?.city;
            },
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4',
            sortValue: (pickup) => {
                let sourceAddress = pickup?.pickupRequestAddresses?.filter(address => address.addressType === 'DESTINATION');
                return sourceAddress[0]?.city; // Sort by consignee city
            },
        },
        {
            label: 'Billing Company Name',
            slug: 'billing-company-name',
            render: (pickup) => {
                let sourceAddress = pickup?.pickupRequestAddresses?.filter(address => address.addressType === 'BILLTO' || address.addressType === 'DESTINATION');
                return sourceAddress[0]?.companyName;
            },
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4',
            sortValue: (pickup) => {
                let sourceAddress = pickup?.pickupRequestAddresses?.filter(address => address.addressType === 'BILLTO' || address.addressType === 'DESTINATION');
                return sourceAddress[0]?.companyName; // Sort by billing company name
            },
        },
        {
            label: 'Billing State',
            slug: 'billing-state',
            render: (pickup) => {
                let sourceAddress = pickup?.pickupRequestAddresses?.filter(address => address.addressType === 'BILLTO' || address.addressType === 'DESTINATION');
                return sourceAddress[0]?.state;
            },
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4',
            sortValue: (pickup) => {
                let sourceAddress = pickup?.pickupRequestAddresses?.filter(address => address.addressType === 'BILLTO' || address.addressType === 'DESTINATION');
                return sourceAddress[0]?.state; // Sort by billing state
            },
        },
        {
            label: 'Billing City',
            slug: 'billing-city',
            render: (pickup) => {
                let sourceAddress = pickup?.pickupRequestAddresses?.filter(address => address.addressType === 'BILLTO' || address.addressType === 'DESTINATION');
                return sourceAddress[0]?.city;
            },
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4',
            sortValue: (pickup) => {
                let sourceAddress = pickup?.pickupRequestAddresses?.filter(address => address.addressType === 'BILLTO' || address.addressType === 'DESTINATION');
                return sourceAddress[0]?.city; // Sort by billing city
            },
        },
        {
            label: 'Assigned Driver',
            slug: 'assigned-driver',
            render: (pickup) => pickup.driver?.user?.firstName ? pickup.driver?.user?.firstName + " " + pickup.driver?.user?.lastName : '',
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4',
            sortValue: (pickup) => pickup.driver?.user?.firstName + " " + pickup.driver?.user?.lastName, // Sort by driver name
        },
        {
            label: 'Assigned Truck',
            slug: 'assigned-truck',
            render: (pickup) => pickup.truck ? pickup.truck.make + " " + pickup.truck.model + ` (${pickup.truck.numberPlate})` : '',
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4',
            sortValue: (pickup) => pickup.truck ? pickup.truck.make + " " + pickup.truck.model + ` (${pickup.truck.numberPlate})` : '', // Sort by truck details
        },
        {
            label: 'Status',
            slug: 'pickup-status',
            render: (pickup) => pickup.pickupStatus,
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4',
            sortValue: (pickup) => pickup.pickupStatus, // Sort by status
        },
        {
            label: 'Shipment Terms',
            slug: 'shipment-terms',
            render: (pickup) => pickup.shipmentTerms,
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4',
            sortValue: (pickup) => pickup.shipmentTerms, // Sort by shipment terms
        },
        {
            label: 'Created At',
            slug: 'created-at',
            render: (pickup) => pickup.createdAt,
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4',
            sortValue: (pickup) => new Date(pickup.createdAt), // Sort by created date (converted to Date object)
        },
        {
            label: 'Api Mode',
            slug: 'api-mode',
            render: (pickup) => pickup.apiMode,
            headerClassName: 'pl-4',
            rowCellClassName: '',
            sortValue: (pickup) => pickup.apiMode, // Sort by API mode
        }
    ];


    const updatedConfig = columns
        .filter(setting => setting.visible)  // Only include visible columns
        .sort((a, b) => a.order - b.order)  // Sort by order
        .map(setting => originalConfig.find(col => col.slug === setting.slug));

    const keyFun = (pickup) => {
        return pickup.id
    }

    const searchHandler = (e) => {
        e.preventDefault();
        // if (e.target.value.length > 2) {
        setSearch(e.target.value);
        // }
    }

    const tabChangeHandle = () => {
        if (selectedTab === 'PickupRequest') {
            setSelectedTab('manualPickupRequest')
        }
        else {
            setSelectedTab('PickupRequest')
        }
    }

    const updatedDateRangeHandle = (range, status) => {
        setDateRange({
            ...dateRange,
            [status]: range
        });
        refetch({
            startDate: range.startDate,
            endDate: range.endDate,
            first: recordsPerPage,
            search: search.length > 3 ? search : '',
            pickupStatus: status // Filter by the specific status group
        });
    }

    const updateColumnsHandle = (data) => {
        setColumns(data);
    }

    return (
        <div>
            <div className="content px-3 lg:px-8">
                <div className="content-bar flex justify-between mt-6">
                    <div className='flex flex-wrap justify-between'>
                        <div className="mr-3">
                            <PageHeading>PICK UP REQUESTS</PageHeading>
                        </div>
                        <button onClick={() => tabChangeHandle()} type="button" className={`mt-3 sm:mt-0 rounded-md ${selectedTab === 'manualPickupRequest' ? 'bg-indigo-600 text-white ' : 'bg-white-600 text-dark border border-black'}bg-indigo-600 px-3 py-2 text-sm font-semibold shadow-sm hover:bg-indigo-500 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 flex items-center`}>
                            <span className='w-[20px] h-[20px] rounded-[50px] bg-red-500 flex items-center justify-center'>
                                <svg fill='#fff' width="12" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                    <path d="M22.7 33.4c13.5-4.1 28.1 1.1 35.9 12.9L224 294.3 389.4 46.2c7.8-11.7 22.4-17 35.9-12.9S448 49.9 448 64V448c0 17.7-14.3 32-32 32s-32-14.3-32-32V169.7L250.6 369.8c-5.9 8.9-15.9 14.2-26.6 14.2s-20.7-5.3-26.6-14.2L64 169.7V448c0 17.7-14.3 32-32 32s-32-14.3-32-32V64C0 49.9 9.2 37.5 22.7 33.4z" /></svg>
                            </span>
                            <span className="ml-2">Manual Pickup</span>
                        </button>

                    </div>
                </div>

            </div>

            {
                selectedTab === 'PickupRequest' &&
                <div className="content px-3 lg:px-8">
                    <div className="content-bar flex justify-between mt-6">
                        <div className="search-bar w-full sm:w-[255px]">
                            <div className="search-field-container flex p-2 pl-4 bg-white border border-gray-300 shadow-sm rounded-md">
                                <img className="pr-2" src={searchIcon} alt="Search Icon" />
                                <input value={search} onInput={(e) => searchHandler(e)} type="text" className="pl-1" placeholder="Customer Business Name" />
                            </div>
                        </div>
                        <div className='w-4/6 flex justify-end'>
                            {/* <div className='w-3/6'>
                                <ReactDateRangePicker startDate={dateRange.startDate} endDate={dateRange.endDate} getUpdatedDateRange={updatedDateRangeHandle} />
                            </div> */}
                            <div className='w-1/6 flex justify-end'>
                                <EditTableColumnSettingModal columns={columns} tableName="pickupRequest" updateColumns={(data) => updateColumnsHandle(data)} />
                            </div>
                        </div>
                    </div>
                    {/* {loading ? <div>
                        <Loader />
                    </div> :
                        <div className="mt-8">
                            <div className="not-prose relative bg-gray-50 rounded-xl overflow-hidden">
                                <div className="absolute inset-0"></div>
                                <div className="relative rounded-xl overflow-auto">
                                    <div className="shadow-sm overflow-x-auto mt-7">
                                        <SortableTable tableLayout="row" data={data} config={updatedConfig} keyFun={keyFun} />
                                    </div>
                                </div>
                                <div className="absolute inset-0 pointer-events-none border border-black/1 rounded-xl"></div>
                            </div>
                            <div className="flex justify-between items-center self-stretch py-6">
                                {count > 0 ? <div>Showing {offset + 1} to {(page) * recordsPerPage} of {count} results</div> : null}
                                {count > recordsPerPage ?
                                    <div className="flex">
                                        <Pagination
                                            to="/bols"
                                            currentPage={page}
                                            totalPages={Math.ceil(count / recordsPerPage)}
                                            onPageChange={handlePageChange}
                                        />
                                    </div>
                                    : null}
                            </div>
                        </div>} */}
                    {loading ? <div>
                        <Loader />
                    </div>
                        : <div>
                            {groupedByStatus.length > 0 ? <div>
                                {groupedByStatus.map((group) => {
                                    const { status, requests } = group;
                                    const currentPage = pagination[status] || 1;
                                    const totalRequests = totalRecords?.find(record => record.status === status)?.count || 0;
                                    const totalPages = Math.ceil(totalRequests / recordsPerPage);
                                    const bgColorClass = pickupStatuses?.find(pStatus => pStatus.value === status)?.bgColor || 'bg-gray-50';
                                    const textColorClass = pickupStatuses?.find(pStatus => pStatus.value === status)?.textColor || 'text-black';
                                    const statusTitle = pickupStatuses?.find(pStatus => pStatus.value === status)?.title || status;

                                    return (
                                        <div className='mb-24' key={status}>
                                            <div className='flex items-center'>
                                                <div className='w-3/6'>
                                                    <h3 className='my-4 text-xl font-medium capitalize relative'>{statusTitle}  <span className={`py-[0px] px-2 rounded-xl bg-bg-primary text-sm text-white font-semibold`}>{totalRequests}</span></h3>
                                                </div>
                                                <div className='w-3/6'>
                                                    <ReactDateRangePicker startDate={dateRange[status]?.startDate || new Date()}
                                                        endDate={dateRange[status]?.endDate || new Date()} getUpdatedDateRange={(data) => updatedDateRangeHandle(data, status)} />
                                                </div>
                                            </div>
                                            <div className={`not-prose relative ${bgColorClass} rounded-xl overflow-hidden`}>
                                                <div className="absolute inset-0"></div>
                                                <div className="relative rounded-xl overflow-auto">
                                                    <div className="shadow-sm overflow-x-auto mt-7">
                                                        <SortableTable tableLayout="row" tableHeaderTextColor={textColorClass} data={requests} config={updatedConfig} keyFun={keyFun} />
                                                    </div>
                                                </div>
                                                <div className="absolute inset-0 pointer-events-none border border-black/10 rounded-xl"></div>
                                            </div>
                                            <div>
                                                {totalPages > 1 && <div className='mt-4 flex justify-end'>
                                                    <GroupPagination
                                                        currentPage={currentPage}
                                                        totalPages={totalPages}
                                                        groupName={status}
                                                        updatePagination={updatePaginationHandle}
                                                    />
                                                </div>}

                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                                :
                                <div>
                                    <p className='text-lg font-bold'>No data found...</p>
                                </div>
                            }
                        </div>
                    }
                </div>
            }
            {selectedTab === 'manualPickupRequest' && <ManualPickupRequest changeTab={tabChangeHandle} />}


        </div >
    );
}

export default PickupRequestPage;