import React, { useContext, useEffect, useRef, useState } from "react";
import searchIcon from '../../assets/search-icon.svg';
import GraphMutation from "../../components/graphql/mutations/Mutation";
import GraphQuery from "../../components/graphql/queries/Query";
import CustomSelect from "../../components/CustomSelect";
import Loader from "../../components/Loader";
import { gql, useQuery } from "@apollo/client";
import { AuthContext } from "../../context/authContext";

const CustomerNotes = ({ customerId }) => {
    const { token } = useContext(AuthContext);

    const GET_PROFILE = gql`
            query {
                me {
                    id,
                    isActive
                }
            }
        `;

    const { data: profileData } = useQuery(GET_PROFILE, {
        context: {
            headers: {
                Authorization: `JWT ${token}`,
            },
        },
        variables: {
            first: 1,
        }
    });

    const { responseData: customerLogData, refetchData } = GraphQuery({ name: 'CustomerLogs', single: 'CustomerLog' }, {
        id: parseInt(customerId),
        skip: 0,
        search: '',
        sortBy: 'id',
        order: 'desc',
    }, `id
        user {
            id,
            firstName,
            lastName,
        }
        parent {
        id
        }
        description
        tags
        createdAt
        updatedAt
        customerLogComments {
            id
            description
            tags
            user {
                id,
                firstName,
                lastName,
            }
            createdAt
        }
    `);

    const { executeMutation: createCustomerLog, responseData: customerLogCreatedData, loading: customerLogLoading, errors: customerLogError } = GraphMutation(
        { name: 'CustomerLog', expectedResponseField: 'customerLog', method: 'create' },
        { name: 'customerLog', type: 'CustomerLogInput' },
        `
        id,
        parent {
            id
        }
        description,
        tags
        createdAt,
        user {
            id,
            firstName,
            lastName,
        }
        updatedAt,
        `
    );

    const { executeMutation: updateCustomerLog, responseData: updatedCustomerLogData, loading: updatedCustomerLogLoading, errors: updatedCustomerLogError } = GraphMutation(
        { name: 'CustomerLog', expectedResponseField: 'customerLog', method: 'update' },
        { name: 'customerLog', type: 'CustomerLogInput' },
        `
            id,
            description,
            parent {
                id
            }
            createdAt,
            tags
            updatedAt,
            customerLogComments {
            id
            description
            tags
            user {
                id,
                firstName,
                lastName,
            }
            createdAt
        }
        `
    );

    const { executeMutation: deleteCustomerLog, responseData: deletedCustomerLogData, loading: deletedCustomerLogLoading, errors: deletedCustomerLogError } = GraphMutation(
        { name: 'CustomerLog', expectedResponseField: 'customerLog', method: 'delete' },
        { name: 'customerLog', type: 'CustomerLogInput' },
        `
            message
            deletedId
        `
    );

    const [customerLogs, setCustomerLogs] = useState([]);
    const [showNewNoteDiv, setShowNewNoteDiv] = useState(false);
    const [formData, setFormData] = useState({ description: '', tags: [] })

    const [showDropdown, setShowDropdown] = useState(false)
    const inputRef = useRef(null);
    const dropdownRef = useRef(null);
    const [searchValue, setSearchValue] = useState('');
    const [filteredLogs, setfilteredLogs] = useState([]);
    const [selectedNotes, setSelectedNotes] = useState([]);
    const [readOnlyLog, setReadOnlyLog] = useState(0);
    const [readOnlyLogComment, setReadOnlyLogComment] = useState(0);
    const [commentBoxId, setCommentBoxId] = useState(null);
    const [editedLog, setEditedLog] = useState(null)
    const [newComment, setNewComment] = useState({ id: null, description: '' })
    const [updatedComment, setUpdatedComment] = useState(null);
    const [rows, setRows] = useState(1);
    const maxRows = 10;
    const textareaRefs = useRef({});
    const [customerNoteTagSearchValue, setCustomerNoteTagSearchValue] = useState('');
    const [customerNoteTagOptions, setCustomerNoteTagOptions] = useState([
        { id: 1, title: 'Agree', slug: 'agree' },
        { id: 2, title: 'Disagree', slug: 'disagree' },
        { id: 3, title: 'Neutral', slug: 'neutral' },
    ]);

    const [user, setUser] = useState({});

    useEffect(() => {
        if (profileData) {
            console.log('profile data', profileData);
            setUser(profileData?.me);
            // setCustomerLogs(customerLogData);
        }
    }, [customerLogData])

    useEffect(() => {
        if (customerLogData) {
            console.log('customer lg data', customerLogData);
            setCustomerLogs(customerLogData);
        }
    }, [customerLogData])

    useEffect(() => {
        if (deletedCustomerLogData) {
            setCustomerLogs((prevData) => {
                // Copy previous data to avoid mutating state directly
                const updatedCustomerLogData = prevData.map((log) => {
                    // If the log has no parent and customerLogComments, handle separately
                    if (!log.parent && log.customerLogComments) {
                        return {
                            ...log,
                            customerLogComments: log.customerLogComments.filter(
                                (comment) => comment.id !== deletedCustomerLogData.deletedId
                            ),
                        };
                    }
                    // Filter logs that are not deleted
                    return log.id !== deletedCustomerLogData.deletedId ? log : null;
                });

                // Remove null values from filtered-out logs
                return updatedCustomerLogData.filter(Boolean);
            });
        }
    }, [deletedCustomerLogData]);

    useEffect(() => {
        if (customerLogCreatedData) {
            console.log('customer log data', customerLogCreatedData);
            if (customerLogCreatedData.parent == null) {
                setCustomerLogs((prevCustomerLogs) => {
                    let updatedDataExist = prevCustomerLogs.findIndex(log => log.id === customerLogCreatedData.id);
                    if (updatedDataExist !== -1) {
                        const updatedLogs = [...prevCustomerLogs];
                        updatedLogs[updatedDataExist] = customerLogCreatedData;  // Replace the log at the found index
                        return updatedLogs;  // Return the new array
                    }
                    return [customerLogCreatedData, ...prevCustomerLogs];
                });
            }
            else {

                setCustomerLogs((prevCustomerLogs) => {
                    const parentLogIndex = prevCustomerLogs.findIndex(log => log.id === customerLogCreatedData.parent.id);
                    if (parentLogIndex !== -1) {
                        const updatedLogs = [...prevCustomerLogs];

                        // Safely update nested array
                        const parentLog = { ...updatedLogs[parentLogIndex] };  // Create a shallow copy of the parent log
                        parentLog.customerLogComments = [
                            ...(parentLog.customerLogComments || []),
                            customerLogCreatedData
                        ];

                        updatedLogs[parentLogIndex] = parentLog;  // Replace with the updated parent log

                        return updatedLogs;  // Return the new array with updated parent
                    } else {
                        return [customerLogCreatedData, ...prevCustomerLogs];
                    }
                });

            }
        }
    }, [customerLogCreatedData])

    useEffect(() => {
        if (readOnlyLogComment !== null) {
            const commentElement = document.getElementById(`comment_${readOnlyLogComment}`);
            if (commentElement) {
                commentElement.focus();  // Focus after state update
            }
        }
    }, [readOnlyLogComment]);

    useEffect(() => {
        if (readOnlyLog !== null) {
            const commentElement = document.getElementById(`customer_log_${readOnlyLog}`);
            if (commentElement) {
                commentElement.focus();  // Focus after state update
            }
        }
    }, [readOnlyLog]);

    // useEffect(() => {
    //  if (filteredLogs.length === 0) {
    //     setfilteredLogs(customerLogs);
    //  }   
    // },[])

    const searchHandler = (e) => {
        e.preventDefault();
        setSearchValue(e.target.value);

        refetchData({ search: e.target.value })

    }

    const editButtonHandle = (id) => {
        console.log('editing id ...', id)
        setReadOnlyLog(id);
    }

    const commentButtonHandle = (id) => {
        console.log('editing id ...', id)
        setCommentBoxId(id == commentBoxId ? null : id);
    }

    const editLogHandle = (e) => {
        let updatedData = [...customerLogs];
        setEditedLog({ ...editedLog, description: e.target.value });
        let editedCustomerLog = updatedData.findIndex(log => log.id === readOnlyLog);
        if (editedCustomerLog !== -1) {
            updatedData[editedCustomerLog] = {
                ...updatedData[editedCustomerLog],
                description: e.target.value,
            }
        }
        setCustomerLogs(updatedData);
    }

    const commentInputChangeHandle = (e) => {

        console.log(e.target.value, 'commment');
        setNewComment({ ...newComment, description: e.target.value });
        // adjustTextareaHeight();
    }

    const adjustTextareaHeight = (inputId) => {
        // const textarea = textareaRef.current;
        const textarea = textareaRefs.current[inputId];
        if (textarea) {
            textarea.rows = 1; // Reset to 1 row to recalculate actual height
            const maxRows = 10; // Optional limit
            const lineHeight = 24; // Adjust according to your CSS

            const currentRows = Math.min(Math.floor(textarea.scrollHeight / lineHeight), maxRows);
            textarea.rows = currentRows;
        }
    };

    const deleteButtonHandle = async (id) => {
        console.log('deleting id ...', id)
        await deleteCustomerLog(null, id)
    }

    const updateLogHandle = async (id, log) => {
        console.log('updating id ...', id)
        console.log(log.description, log.tags, 'edited Log  ...', editedLog, 'condition', (editedLog != null))
        setReadOnlyLog(readOnlyLog == id ? 0 : id)
        if (editedLog != null) {
            let input = {
                customerId: parseInt(customerId),
                description: editedLog.description,
                tags: log.tags,
            }
            await updateCustomerLog(input, parseInt(id));
            setEditedLog(null);
        }
    }

    const updateLogCommentHandle = async () => {
        if (updatedComment != null) {
            let input = {
                customerId: parseInt(customerId),
                description: updatedComment.description,
            }
            await updateCustomerLog(input, parseInt(updatedComment.id));
            setUpdatedComment(null);
        }
    }

    const editCommentChangeHandle = async (e, commentId, commentIndex, logIndex, logs) => {
        console.log(e.target.value, 'log id ==>', commentId, 'log_comment', logs[commentIndex]);
        setUpdatedComment({ id: commentId, description: e.target.value });
        let updatedCustomerLogs = [...customerLogs];
        updatedCustomerLogs[logIndex] = {
            ...updatedCustomerLogs[logIndex],
            customerLogComments: [
                ...customerLogs[logIndex].customerLogComments.map((comment, index) => {
                    if (index === commentIndex) {
                        return {
                            ...comment,
                            description: e.target.value,
                        }
                    }
                    return comment;
                })
            ]
        }
        console.log(updatedCustomerLogs, 'after update');
        setCustomerLogs(updatedCustomerLogs);
        // adjustTextareaHeight();
    }

    const submitComment = async (log_id) => {
        if (newComment.description != '') {
            await createCustomerLog({
                customerId: parseInt(customerId),
                parentId: parseInt(log_id),
                description: newComment.description,
            })
            setNewComment({ ...newComment, description: '' })
        }
    }

    const timeAgo = (date) => {
        const seconds = Math.floor((new Date() - new Date(date)) / 1000);

        const intervals = {
            year: 31536000,
            month: 2592000,
            day: 86400,
            hour: 3600,
            minute: 60,
            second: 1,
        };

        for (let key in intervals) {
            const interval = Math.floor(seconds / intervals[key]);
            if (interval >= 1) {
                return `${interval} ${key}${interval > 1 ? 's' : ''} ago`;
            }
        }
        return 'just now';
    };

    const commentBlurHandle = (commentId) => {
        setReadOnlyLogComment(readOnlyLogComment == commentId ? 0 : commentId)
        updateLogCommentHandle();
    }
    const editCommentButtonHandle = (commentId) => {
        setReadOnlyLogComment(commentId);
        // let commentElement = document.getElementById('comment_'+commentId);
        // console.log(commentElement);
        // if (commentElement) {
        //     commentElement.focus(); // Correct way to focus the element
        // } else {
        //     console.error(`Input element with id 'comment_${commentId}' not found.`);
        // }
    }

    const renderCustomerLogComments = (log, logIndex) => {
        if (log?.customerLogComments?.length > 0) {
            let comments = log.customerLogComments;
            return comments.map((comment, index) => {
                return (
                    <div key={index}>
                        <div className="items-start justify-start px-2 my-2 overflow-hidden bg-[#d5e0ff] border rounded-xl">
                            {/* <div className="w-[15%]"></div> */}
                            <div className="w-[100%]">
                                <h4 className="px-2 font-bold text-md">{comment?.user?.firstName} {comment?.user?.lastName[0]?.toUpperCase()}</h4>
                                <textarea
                                    id={"comment_" + comment.id}
                                    ref={(el) => (textareaRefs.current[comment.id] = el)}
                                    onInput={() => adjustTextareaHeight(comment.id)}
                                    disabled={readOnlyLogComment == comment.id ? false : true}
                                    readOnly={readOnlyLogComment == comment.id ? false : true}
                                    onBlur={() => commentBlurHandle(comment.id)}
                                    style={{ resize: 'none', overflow: 'hidden', lineHeight: '24px' }}
                                    rows={rows}
                                    onChange={(e) => editCommentChangeHandle(e, comment.id, index, logIndex, comments)}
                                    value={comment.description}
                                    className="w-full px-2 bg-[#d5e0ff] rounded-md outline-none text-md focus:border focus:border-black">
                                </textarea>
                            </div>
                            <div className="w-[100%] flex items-center justify-between">
                                <div>
                                    {user.id == comment?.user?.id && <div>
                                        <button onClick={() => editCommentButtonHandle(comment.id)} className="px-2 py-2 text-sm font-semibold text-blue-600 hover:underline">
                                            Edit
                                        </button>|
                                        <button onClick={() => deleteButtonHandle(comment.id)} className="px-2 py-2 text-sm font-semibold text-red-600 hover:underline">
                                            Delete
                                        </button>
                                        {updatedCustomerLogLoading && <span>Updating...</span>}
                                    </div>}
                                </div>
                                <span className="text-xs text-blue-500">{timeAgo(comment.createdAt)}</span>
                            </div>
                        </div>
                    </div>
                )
            })
        }
        return '';
    }

    const updateCustomerSelectedNoteTagHandle = (data, logIndex) => {
        setCustomerLogs(() => {
            let updatedLogs = [...customerLogs];
            if (updatedLogs[logIndex] != null) {
                updatedLogs[logIndex] = {
                    ...updatedLogs[logIndex],
                    tags: data,
                }
            }
            return updatedLogs;
        })
    }

    const renderCustomerLogs = () => {
        if (customerLogs.length > 0) {
            return customerLogs.map((log, logIndex) => {
                if (log.parent == null) {
                    return (
                        <div key={log.id} className={`rounded-md mb-3 block px-4 py-2 bg-blue-50 text-black  dark:hover:bg-black-200 dark:hover:text-black border`} onClick={() => selectSuggestionHandle(log)}>
                            <div>
                                <h4 className="px-2 font-bold text-md">{log?.user?.firstName} {log?.user?.lastName[0]?.toUpperCase()}</h4>
                                <textarea
                                    id={`customer_log_${log.id}`}
                                    ref={(el) => (textareaRefs.current[log.id] = el)}
                                    onInput={() => adjustTextareaHeight(log.id)}
                                    style={{ resize: 'none', overflow: 'hidden', lineHeight: '24px' }}
                                    className="w-full px-2 bg-blue-50"
                                    disabled={readOnlyLog == log.id ? false : true}
                                    readOnly={readOnlyLog == log.id ? false : true}
                                    onChange={editLogHandle}
                                    // onFocus={() => editButtonHandle(log.id)}
                                    // onBlur={() => updateLogHandle(log.id,log.description)}
                                    value={log.description}
                                >
                                </textarea>
                                {
                                    readOnlyLog == log.id && <div>
                                        <CustomSelect labelColor="#FF9C73" labelTextColor='#000000' multiple={true} selectedOptions={log.tags} searchValue={customerNoteTagSearchValue} setSearchValue={customerNoteTagSearchHandle} options={customerNoteTagOptions} placeholderValue="Select Note Tag" getSuggestions={(value) => suggestionHandler(value)} setSelectedItems={(data) => updateCustomerSelectedNoteTagHandle(data, logIndex)} updateOptions={updateOptionsHandle} />

                                        <button
                                            className="px-2 my-3 ml-1 text-white bg-green-600 rounded-md"
                                            onClick={() => updateLogHandle(log.id, log)}>
                                            Update
                                        </button>
                                    </div>
                                }
                            </div>
                            <div className="flex items-center justify-between">
                                {/* <button className="px-2 my-3 ml-1 text-white bg-green-600 rounded-md" onClick={() => editButtonHandle(log.id)}>Edit</button> */}
                                <div>
                                    <button className="ml-1 text-sm font-semibold text-blue-600 bg-transparent rounded-md hover:underline" onClick={() => commentButtonHandle(log.id)}>comments</button> |
                                    {user.id == log?.user?.id && <span>
                                        <button className="ml-1 text-sm font-semibold text-blue-600 bg-transparent rounded-md hover:underline" onClick={() => editButtonHandle(log.id)}>Edit</button> |
                                        <button className="ml-1 text-sm font-semibold text-red-600 bg-transparent rounded-md" onClick={() => deleteButtonHandle(log.id)}>Delete</button>
                                    </span>
                                    }
                                    <span className="text-xs text-blue-500"> ({timeAgo(log.createdAt)})</span>
                                </div>
                                {readOnlyLog !== log.id && <div>
                                    {log.tags.map((tag, tagIndex) => {
                                        return (
                                            <span key={tagIndex} className="inline-flex items-center justify-between px-2 mb-1 mr-2 text-sm rounded-[50px] bg-green-200">{tag}</span>
                                        )
                                    })}
                                </div>}
                            </div>
                            {commentBoxId == log.id && <div className="pl-10">
                                <div className="flex items-start justify-start pt-1 overflow-hidden border border-blue-900 rounded-xl">
                                    <div className="w-[87%]">
                                        <div>
                                            <textarea
                                                ref={(el) => (textareaRefs.current[log.id + '_new'] = el)}
                                                onInput={() => adjustTextareaHeight(log.id + '_new')}
                                                style={{ resize: 'none', overflow: 'hidden', lineHeight: '24px' }}
                                                placeholder="Enter Your Comment Here..."
                                                rows={rows}
                                                className="w-full px-2 outline-none bg-blue-50"
                                                onChange={commentInputChangeHandle}
                                                value={newComment.description}
                                            >
                                            </textarea>
                                        </div>
                                    </div>
                                    <div className="w-[13%]">
                                        <button onClick={() => submitComment(log.id)} className="px-2 font-semibold text-green-600 hover:underline">
                                            Send
                                        </button>
                                    </div>
                                </div>
                                <div>
                                    {renderCustomerLogComments(log, logIndex)}
                                </div>
                            </div>}
                        </div>
                    )
                }
                return '';
            })
        }
    }

    const selectSuggestionHandle = (value) => {
        // let oldNotes = selectedNotes;
        // if (oldNotes.length > 0 && oldNotes?.includes(value.slug)) {
        //     console.log('exists')
        //     oldNotes = oldNotes.filter(option => option != value.slug);
        //     // setSelectedCustomerLabels(updatedNotes)
        // }
        // else {
        //     console.log('not exists')
        //     oldNotes.push(value.slug);
        // }
        // // setSelectedItems(oldNotes);
        // setShowDropdown(false)
    }

    const removeSelectedOption = (option) => {
        let prevData = selectedNotes;
        let updatedData = prevData.filter(label => label != option);
        // setSelectedItems(updatedData);
    }

    const addOptionHandle = () => {
        setShowNewNoteDiv(!showNewNoteDiv)
    }

    const enterKeyPress = (e) => {
        if (e.keyCode === 13) {
            addOptionHandle();
        }
    }

    const resetButtonHandle = () => {
        setFormData({ description: '' })
    }
    const saveButtonHandle = async () => {
        console.log(formData);
        if (formData?.description?.length > 0) {
            await createCustomerLog({
                customerId: parseInt(customerId),
                description: formData.description,
                tags: formData.tags,
            })
            setShowNewNoteDiv(!showNewNoteDiv)
            setFormData({ description: '', tags: [] })
        }
    }

    const descriptionChangeHandle = (e) => {
        setFormData({ description: e.target.value })
    }

    const notesSearchFocusHandle = () => {
        setShowDropdown(true);
        setShowNewNoteDiv(false);
    }

    const customerNoteTagSearchHandle = (data) => {
        setCustomerNoteTagSearchValue(data);
    }

    const suggestionHandler = (data) => {
        console.log(data, 'sugg handler');
    }

    const selectCustomerNoteTagHandle = (data) => {
        // setSelectedCustomerLabels(data)
        setEditedLog({ ...editedLog, tags: data });
        setFormData({
            ...formData,
            tags: data,
        });
    }

    const updateOptionsHandle = (option) => {
        let prevData = [...customerNoteTagOptions];
        setCustomerNoteTagOptions(() => {
            if (prevData?.some(item => item.slug == option.slug)) {
                return [...customerNoteTagOptions];
            }
            return [...customerNoteTagOptions, option]
        })
        console.log('updated customer options', customerNoteTagOptions)
    }

    return (
        <div className="relative search-bar">
            <div>
                <h2 className="mb-3 uppercase text-md">Customer Notes</h2>
            </div>
            <div className="flex p-2 pl-4 bg-white border border-gray-300 rounded-md shadow-sm search-field-container">
                <img className="pr-2" src={searchIcon} alt="Search Icon" />
                <input onKeyDown={enterKeyPress} ref={inputRef} onFocus={() => notesSearchFocusHandle()} value={searchValue} onInput={(e) => searchHandler(e)} type="text" className="w-full pl-1 border-inherit" placeholder={''} />
                {/* <button className="px-2 ml-1 text-white bg-green-600 rounded-md" onClick={() => addOptionHandle()}>{ showNewNoteDiv  === true ? 'Close' : 'Add'}</button> */}
            </div>
            <div>
                <button className="px-2 my-3 ml-1 text-white bg-green-600 rounded-md" onClick={() => addOptionHandle()}>{showNewNoteDiv === true ? 'Close' : 'Add New Note'}</button>
                {showNewNoteDiv && <div>
                    <textarea value={formData.description} onChange={descriptionChangeHandle} rows={7} minLength={10} className="w-full p-2 border rounded-md"></textarea>
                    <div>
                        <CustomSelect labelColor="#FF9C73" labelTextColor='#000000' multiple={true} selectedOptions={formData.tags} searchValue={customerNoteTagSearchValue} setSearchValue={customerNoteTagSearchHandle} options={customerNoteTagOptions} placeholderValue="Select Note Tag" getSuggestions={(value) => suggestionHandler(value)} setSelectedItems={selectCustomerNoteTagHandle} updateOptions={updateOptionsHandle} />
                    </div>
                    <div>
                        <button className="px-2 my-3 ml-1 text-white bg-gray-600 rounded-md" onClick={() => resetButtonHandle()}>Reset</button>
                        <button className="px-2 my-3 ml-1 text-white bg-green-600 rounded-md" onClick={() => saveButtonHandle()}>Add Note</button>
                    </div>
                </div>}
            </div>
            {(customerLogLoading === true || updatedCustomerLogLoading === true || deletedCustomerLogLoading === true) ?
                <Loader />
                :
                <div ref={dropdownRef} className="bg-white rounded-md ">
                    <h3 className="text-md mb-3">Customer logs</h3>
                    {customerLogs.length > 0 && <div className="w-full">
                        <div className="">
                            {renderCustomerLogs()}
                        </div>
                    </div>}
                </div>
            }
        </div>
    );
}

export default CustomerNotes;